import { TableCell } from '@library/components/atoms/TableCell';
import { Tag } from '@library/components/molecules';
import { CellContext } from '@tanstack/react-table';

import { SubscriptionOverview } from '@shared/data/types';

export const PlanRenderer = (props: CellContext<SubscriptionOverview, string>) => {
  const planName = props.row.original.planName;
  return (
    <TableCell>
      <div className="flex gap-2">
        <Tag size="medium" text={planName} template="OUTLINE" />
      </div>
    </TableCell>
  );
};
