import { Icon } from '@library/components/atoms';

import { Label } from '../Label';

interface CheckoutPatientDecisionButtonProps {
  labelText: string;
  helperText: string;
  onClick: () => void;
  icon: string;
  testId: string;
}

export const CheckoutPatientDecisionButton = ({
  onClick,
  labelText,
  helperText,
  icon,
  testId,
}: CheckoutPatientDecisionButtonProps) => (
  <button data-testid={testId} className="text-left" key={labelText} onClick={onClick}>
    <div className="flex border border-borderNeutralDefaultDisabled p-5 rounded-2xl border-solid">
      <div className="flex flex-col gap-3 grow">
        <span className="text-3xl">{icon}</span>
        <Label element="div" size="medium" labelText={labelText} helperText={helperText} appearance="secondary"></Label>
      </div>
      <Icon color="text-foregroundNeutralQuaternary" iconName="arrow-right-outlined" size="medium" />
    </div>
  </button>
);
