import { memo, useMemo, useRef, useState } from 'react';
import { BaseLabel, Button, Input, Selector } from '@library/components/molecules';
import { ToggleTab } from '@library/components/molecules/ToggleTab';
import { t } from 'i18next';

import { GuidePractitioner, PatientAction, PatientTableView } from '@shared/data/types';
import { useFeatureFlags, useTreatmentGuideContext } from '@shared/hooks';

import { RefetchPatientOverview } from '../columnDefs';

export type ActiveFilters = {
  practitioner?: string;
  patientAction?: PatientAction;
  archived?: boolean;
};

const defaultStatusOptions = [
  { name: 'All statuses', value: '' },
  { name: 'Declined', value: PatientAction.DECLINED },
  { name: 'Finance accepted', value: PatientAction.PAID_WITH_FINANCE },
  { name: 'Finance declined', value: PatientAction.FINANCE_DECLINED },
  { name: 'Finance processed', value: PatientAction.FINANCE_PROCESSED },
  { name: 'Guide not delivered', value: PatientAction.GUIDE_BOUNCED },
  { name: 'Guide sent', value: PatientAction.GUIDE_SENT },
  { name: 'Follow-up sent', value: PatientAction.FOLLOW_UP_SENT },
  { name: 'Interested', value: PatientAction.INTERESTED },
  { name: 'Not sure', value: PatientAction.NOT_SURE },
  { name: 'Pay in practice', value: PatientAction.PAY_IN_PRACTICE },
  { name: 'Viewed', value: PatientAction.VIEWED },
];

const financeStatusOptions = [
  { name: 'All statuses', value: '' },
  { name: 'Finance accepted', value: PatientAction.PAID_WITH_FINANCE },
  { name: 'Finance processed', value: PatientAction.FINANCE_PROCESSED },
];

const guideStatusOptions = [
  { name: 'Active guides', value: 'Active guides' },
  { name: 'Archived guides', value: 'Archived guides' },
];

const tableViewOptions = [
  { label: 'Default view', value: PatientTableView.DEFAULT },
  { label: 'Finance only', value: PatientTableView.FINANCE },
];

type Props = {
  displayedRowCount?: number;
  guidePractitioners?: GuidePractitioner[];
  activeFilters: ActiveFilters | null;
  setActiveFilters: RefetchPatientOverview;
  setFilter: (event: string) => void;
  patientTableView?: PatientTableView;
  handleDownloadCsv?: RefetchPatientOverview;
};

export const TableFilters = memo(
  ({
    displayedRowCount,
    guidePractitioners,
    activeFilters,
    setActiveFilters,
    setFilter,
    patientTableView,
    handleDownloadCsv,
  }: Props) => {
    const featureFlags = useFeatureFlags();
    const [selectedViewIndex, setSelectedViewIndex] = useState(0);
    const treatmentGuideContext = useTreatmentGuideContext();
    const practitionerOptions = useMemo(
      () =>
        guidePractitioners && guidePractitioners.length
          ? [
              {
                name: 'All practitioners',
                value: '',
              },
              {
                name: 'No practitioners',
                value: 'none',
              },
            ].concat(
              guidePractitioners.map((obj) => {
                return {
                  name: obj.name,
                  value: obj.id,
                };
              }),
            )
          : [],
      [guidePractitioners],
    );
    const [practitionerIndex, setPractitionerIndex] = useState<number>(0);
    const [statusIndex, setStatusIndex] = useState<number>(0);
    const [actionIndex, setActionIndex] = useState<number>(0);

    const textRef = useRef<HTMLInputElement>(null);
    const filterByPatientAction = (action: PatientAction) => {
      setActiveFilters({ patientAction: action || undefined });
    };

    const filterByGuideStatus = (status: string) => {
      switch (status) {
        case 'Archived guides': {
          setActiveFilters({ archived: true });
          break;
        }
        case 'Active guides': {
          setActiveFilters({ archived: false });
          break;
        }
      }
    };

    const filterByGuidePractitioner = (practitioner: string) => {
      switch (practitioner) {
        case '': {
          setActiveFilters({ practitioner: undefined });
          break;
        }
        case 'none': {
          setActiveFilters({ practitioner: 'none' });
          break;
        }
        default: {
          setActiveFilters({ practitioner: practitioner });
          break;
        }
      }
    };

    const filterByView = (index: number) => {
      setSelectedViewIndex(index);
      setActiveFilters({ patientAction: undefined, view: tableViewOptions[index].value });
    };

    const resetFilters = () => {
      setFilter('');
      setSelectedViewIndex(0);
      setActionIndex(0);
      setPractitionerIndex(0);
      setStatusIndex(0);
      if (textRef.current) {
        textRef.current.value = '';
      }
      setActiveFilters({
        archived: undefined,
        patientAction: undefined,
        practitioner: undefined,
        view: PatientTableView.DEFAULT,
      });
    };
    let statusOptions = [];

    if (patientTableView === PatientTableView.FINANCE) {
      statusOptions = financeStatusOptions;
    } else {
      statusOptions = defaultStatusOptions;
    }

    return (
      <div>
        {featureFlags.loanData && (
          <div className="w-[20rem] mb-8">
            <ToggleTab options={tableViewOptions} selectedIndex={selectedViewIndex} setSelected={filterByView} />
          </div>
        )}
        <div className="flex items-center justify-between mb-10">
          <div className="flex items-center gap-5">
            <div className="w-60">
              <Input
                ref={textRef}
                testId="filter-text-box"
                leftIcon="search"
                inputSize="small"
                placeholder="Search by name or email"
                onChange={(event) => setFilter(event.target.value)}
              />
            </div>
            <div className="flex items-center gap-3">
              <Selector
                testId="filter-status"
                className="!w-48"
                options={statusOptions}
                placeholder="All statuses"
                size="small"
                onChange={({ value }) => filterByPatientAction(value as PatientAction)}
                icons={{ open: 'chevron-down', close: 'chevron-up' }}
                defaultSelectedIndex={0}
                externalIndex={actionIndex}
                setExternalIndex={setActionIndex}
              />
              <Selector
                testId="filter-practitioners"
                className="!w-48"
                options={practitionerOptions}
                placeholder="All practitioners"
                size="small"
                onChange={({ value }) => filterByGuidePractitioner(value)}
                icons={{ open: 'chevron-down', close: 'chevron-up' }}
                errorTitle={t('practice.patientDashboard.filters.errors.practitioners.title')}
                errorDescription={t('practice.patientDashboard.filters.errors.practitioners.description')}
                defaultSelectedIndex={practitionerOptions.length ? 0 : undefined}
                externalIndex={practitionerIndex}
                setExternalIndex={setPractitionerIndex}
              />
              <Selector
                testId="filter-guide-status"
                className="!w-44"
                options={guideStatusOptions}
                placeholder="Active guides"
                size="small"
                onChange={({ value }) => filterByGuideStatus(value)}
                icons={{ open: 'chevron-down', close: 'chevron-up' }}
                defaultSelectedIndex={0}
                externalIndex={statusIndex}
                setExternalIndex={setStatusIndex}
              />
            </div>
            <BaseLabel
              size="small"
              className=" !text-foregroundBrandPrimary cursor-pointer"
              onClick={resetFilters}
              text="Reset"
              strong
              testId="reset-filters-button"
            />
            <BaseLabel
              className="!text-foregroundNeutralSecondary"
              size="small"
              text={
                displayedRowCount === 1
                  ? t('practice.patientDashboard.filters.singleCount', { count: 1 })
                  : t('practice.patientDashboard.filters.multipleCount', {
                      count: displayedRowCount === -1 ? 0 : displayedRowCount,
                    })
              }
            />
          </div>
          {patientTableView === PatientTableView.FINANCE ? (
            <Button
              onClick={() => handleDownloadCsv!({ ...activeFilters })}
              appearance="subtle"
              mode="accent"
              size="small"
              testId="download-csv"
              text="Export CSV"
              leftIcon="download"
            />
          ) : (
            <Button
              data-tourid="add-patient-button"
              onClick={() => {
                treatmentGuideContext?.setTreatmentGuideModalData({ treatmentGuideId: '', isOpen: true });
              }}
              appearance="primary"
              mode="accent"
              size="small"
              testId="add-patient"
              text="New guide"
              leftIcon="add"
            />
          )}
        </div>
      </div>
    );
  },
);

TableFilters.displayName = 'TableFilters';
