import { useState } from 'react';
import { t } from 'i18next';

import { useGetAddresses } from '@shared/data/practice/hooks';
import { validations } from '@shared/utils/validations';

import { Address } from './PostcodeInput';

export const usePostcodeLookUp = (): [Address[], (postcode: string) => void, string | null] => {
  const { mutate } = useGetAddresses();
  const [addressOptions, setAddressOptions] = useState<Address[]>([]);
  const [apiError, setApiError] = useState<string | null>(null);
  const searchByPostcode = (postcode: string) => {
    const { success } = validations.postcode.safeParse(postcode);
    if (success) {
      mutate(
        { postcode },
        {
          onSuccess: (data) => {
            setAddressOptions(data.data.addresses);
          },
          onError: () => {
            setApiError(t('plans.patientPayerInfo.billingInfo.error.addressNotFound'));
          },
        },
      );
    }

    setAddressOptions([]);
  };

  return [addressOptions, searchByPostcode, apiError];
};
