import { useState } from 'react';
import { TableCell } from '@library/components/atoms/TableCell';
import { TableHeader } from '@library/components/molecules';
import { convertToCurrency } from '@library/utils';
import {
  CellContext,
  ColumnDef,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { t } from 'i18next';

import { formatCreatedAtValue } from '@practice/pages/PatientsPages/PatientsDashboard/PatientTable/utils';
import { PayoutStatusRenderer } from '@practice/pages/PatientsPages/PatientsDashboard/PayoutTable/CellRenderers/PayoutStatusRenderer/PayoutStatusRenderer';
import { PayoutOverview } from '@shared/data/types';

type CellFn<T> = CellContext<PayoutOverview, T>;

interface Props {
  payoutsOverview: PayoutOverview[];
}

export const useTable = ({ payoutsOverview }: Props) => {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [filter, setFilter] = useState('');
  const [pageIndex, setPageIndex] = useState(0);

  const createCell = (data: CellFn<string>) => <TableCell>{data.getValue()}</TableCell>;

  const columns: ColumnDef<PayoutOverview>[] = [
    {
      id: 'Month',
      accessorFn: (data: PayoutOverview) => {
        return data.collectionMonth;
      },
      cell: createCell,
      size: 260,
      enableSorting: false,
    },
    {
      id: 'Total collected',
      accessorFn: (data: PayoutOverview) => {
        return convertToCurrency(data.totalCollected, '£');
      },
      cell: createCell,
      size: 260,
      header: (props) => {
        return <TableHeader {...props} tooltipText={t('practice.plansDashboard.headerTooltip.collectedPayments')} />;
      },
      enableSorting: false,
    },

    {
      id: 'Status',
      accessorFn: (data: PayoutOverview) => data.status,
      cell: PayoutStatusRenderer,
      size: 260,
      enableSorting: false,
    },
    {
      id: 'Payment date',
      accessorFn: (data: PayoutOverview) => data.payoutDate,
      cell: (data: CellFn<string>) => <TableCell>{formatCreatedAtValue(data.getValue())}</TableCell>,
      size: 260,
      header: (props) => {
        return <TableHeader {...props} tooltipText={t('practice.plansDashboard.headerTooltip.paymentDate')} />;
      },
      enableSorting: false,
    },
  ];

  const table = useReactTable({
    data: payoutsOverview,
    state: {
      sorting,
      globalFilter: filter,
      pagination: {
        pageSize: 7,
        pageIndex,
      },
      columnVisibility: {
        email: false,
      },
    },
    defaultColumn: {
      header: TableHeader,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    columns,
  });

  return {
    table,
    setFilter,
    setSorting,
    setPageIndex,
  };
};
