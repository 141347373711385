import { Icon } from '@library/components/atoms';
import { t } from 'i18next';

import { BaseLabel } from '../BaseLabel';

export const InfoMessage = () => {
  return (
    <div className="flex gap-3 bg-backgroundNeutralSoft py-3 px-6 items-center justify-center h-10 mb-5">
      <Icon size="xSmall" color="!text-foregroundNeutralTertiary" iconName="info" />
      <BaseLabel
        className="!text-foregroundNeutralTertiary"
        size="xSmall"
        text={t('practice.patientDashboard.financeOnlyData')}
      />
    </div>
  );
};
