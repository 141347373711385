import { memo, MouseEvent } from 'react';
import { IconName } from '@library/components/atoms/Icon/utils';
import { getCountClass, getTabItemClasses } from '@library/components/molecules/TabItem/utils';
import { transitionClasses } from '@library/utils';
import clsx from 'clsx';

import { Label } from '../Label';

const tabItemClass = `
  flex
  items-center
  justify-center
  outline-none
  cursor-pointer
  [&_*]:cursor-pointer
  group
`;

const tabItemHoverClass = `
  hover:border-borderNeutralDefaultHover
`;

const tabItemSelectedClass = `
  !border-backgroundBlueSoft
  bg-backgroundBlueSoft
`;

const focusClass = `
  focus:ring-borderBrandDefault focus:ring-[3px]
  border
  border-borderNeutralDefault
`;

export interface TabItemProps {
  size?: 'small' | 'medium' | 'large';
  index: number;
  icon?: IconName;
  label?: string;
  count?: number;
  isSelected: boolean;
  focusOutline?: boolean;
  handleOnClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  handleKeyDown: React.KeyboardEventHandler<HTMLButtonElement>;
}

export const TabItem = memo(
  ({
    size = 'medium',
    icon,
    label,
    count,
    isSelected,
    handleOnClick,
    handleKeyDown,
    focusOutline = true,
  }: TabItemProps) => {
    return (
      <button
        className={clsx(
          isSelected && tabItemSelectedClass,
          tabItemClass,
          tabItemHoverClass,
          getTabItemClasses(size),
          transitionClasses,
          focusOutline ? focusClass : 'border-none ',
        )}
        onKeyDown={handleKeyDown}
        onClick={(event) => {
          if (!isSelected) handleOnClick?.(event);
        }}
        role="tab"
        aria-selected={isSelected}
      >
        <Label
          className={clsx(transitionClasses)}
          size={size}
          labelText={label}
          labelIcon={icon}
          labelTextClassName={clsx(isSelected ? 'text-foregroundBrandPrimary' : 'text-foregroundNeutralPrimary')}
        />
        {count && <Label size={size} labelText={`${count}`} className={clsx(getCountClass(isSelected))} />}
      </button>
    );
  },
);

TabItem.displayName = 'TabItem';
