import { PropsWithChildren } from 'react';
import { Icon } from '@library/components/atoms';
import { InputProps } from '@library/components/molecules/Input';

export const InputContainer = ({
  children,
  leftIcon,
  rightIcon,
  inputSize,
}: PropsWithChildren<Pick<InputProps, 'leftIcon' | 'rightIcon' | 'inputSize'>>) => {
  let classes = 'absolute translate-y-[-50%] top-[50%] leading-none ';

  if (leftIcon) {
    classes += 'left-[10px]';
  } else if (rightIcon) {
    classes += 'right-[10px]';
  }
  if (leftIcon || rightIcon) {
    return (
      <div className="relative">
        <div className={classes}>
          <Icon
            iconName={leftIcon || rightIcon!}
            color="currentColor"
            size={inputSize === 'small' || inputSize === 'medium' ? 'xSmall' : 'medium'}
          />
        </div>
        <span>{children}</span>
      </div>
    );
  }
  return children;
};
