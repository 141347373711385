export type GuidePractitioner = {
  id: string;
  practiceId: string;
  name: string;
};

export type GuidePractitionerArgs = {
  id: string;
};

export type StageName =
  | 'Implant assessment'
  | "Dentist's assessment summary"
  | 'Implant placement'
  | 'Post-placement review'
  | 'Implant exposure'
  | 'Crown impression'
  | 'Crown fitting'
  | 'Maintenance review'
  | 'Bone graft'
  | 'Sinus lift'
  | 'Book appointment'
  | 'Extraction'
  | 'CBCT Scan'
  | 'Clear aligners assessment'
  | 'Assessment scans'
  | 'Assessment summary'
  | 'Preparation'
  | 'Fitting appointment'
  | 'Progress review'
  | 'Progress impressions'
  | 'Final review'
  | 'Aftercare';

export type StageIdentifier =
  | 'implant-assessment'
  | 'dentist-summary'
  | 'implant-placement'
  | 'post-placement-review'
  | 'implant-exposure'
  | 'crown-impression'
  | 'crown-fitting'
  | 'maintenance-review'
  | 'bone-graft'
  | 'sinus-lift'
  | 'book-appointment'
  | 'extraction'
  | 'cbct-scan'
  | 'clear-aligners-review'
  | 'clear-aligners-scans'
  | 'clear-aligners-assessment-summary'
  | 'clear-aligners-prep'
  | 'clear-aligners-fitting'
  | 'clear-aligners-progress-review'
  | 'clear-aligners-progress-impressions'
  | 'clear-aligners-final-review'
  | 'clear-aligners-aftercare'
  | 'clear-aligners-book-appointment';

export type Stage = {
  id: string;
  name: StageName;
  description: string;
  isConsented: boolean | null;
  consentTime: string | null;
  endTime: string | null;
  startTime: string | null;
  duration: number | null;
  lapsedTime: number | null;
  identifier: StageIdentifier;
};

export enum PaymentMethod {
  FINANCE = 'FINANCE',
  PAY_IN_PRACTICE = 'PAY_IN_PRACTICE',
  NOT_READY_YET = 'NOT_READY_YET',
  PAY_VIA_PRACTI = 'PAY_VIA_PRACTI',
}

export enum IPaymentMethod {
  FINANCE = 'FINANCE',
  PAY_IN_PRACTICE = 'PAY_IN_PRACTICE',
}

export enum NotReadyReason {
  PRICE_TOO_HIGH = 'PRICE_TOO_HIGH',
  ANXIOUS_ABOUT_TREATMENT = 'ANXIOUS_ABOUT_TREATMENT',
  NEED_MORE_INFORMATION = 'NEED_MORE_INFORMATION',
  NOT_ELIGIBLE_FOR_FINANCE = 'NOT_ELIGIBLE_FOR_FINANCE',
}

export type Order = {
  createTime: string;
  id: string;
  journeyId: string;
  paymentMethod: PaymentMethod;
  notReadyReason?: NotReadyReason;
  status: 'pending' | 'success' | 'cancelled' | 'rejected';
};

export type AddPractitioner = {
  givenName: string;
  familyName: string;
  email: string;
  receiveNotifications?: boolean;
};

export type AddGuidePractitioner = {
  name: string;
};

export type PracticePatient = {
  id: string;
  phone?: string;
  email: string;
  firstName: string;
  lastName: string;
  accountStatus?: string;
  treatmentGuide: TreatmentGuide[];
};

export type PracticeMetrics = {
  active: number;
  inTreatment: number;
  revenue: number;
};

export type PatientAddress = {
  houseNameOrNumber?: string;
  addressLine1?: string;
  addressLine2?: string;
  county?: string;
  city?: string;
  postcode: string;
};

export type PatientUserDetailsPatch = {
  givenName?: string;
  familyName?: string;
  address?: PatientAddress;
  smileQuizSkipped?: true;
};

export type SmileOrFeedbackQuiz = {
  question1: string[];
  question2: string[];
  question3: string[];
};

export type UpdateQuiz = Partial<SmileOrFeedbackQuiz>;

export type Quiz = QuizResponse | QuizRes;

export type QuizType = 'SMILE' | 'FEEDBACK';

export type QuizRes = {
  id: string;
  type: QuizType;
  createdAt: string;
  updatedAt: string;
  patientId: string;
};

export type QuizResponse = QuizRes & {
  data: SmileOrFeedbackQuiz;
};

export type UpdateAnswers = {
  question1?: string[];
  question2?: string[];
  question3?: string[];
};

export type QuizState = {
  step: number;
  answers: SmileOrFeedbackQuiz;
};

export type QuizActions = {
  incrementStep: () => void;
  setAnswers: (answers: UpdateAnswers) => void;
  resetQuiz: () => void;
};

export type PatientSoftSearchKeyArgs = {
  orderTotal: number;
};

export enum InstalmentType {
  IFC = 'ifc',
  IBC = 'ibc',
  REPRESENTATIVE_EXAMPLE = 'representative_example',
}

export interface DivideBuyInstalment {
  type: InstalmentType;
  cash_price: number;
  term_length: number;
  min_price: number | null;
  max_price: number | null;
  available: boolean | null;
  interest_rate: number;
  monthly_instalment_cost: number;
  total_to_pay: number;
  total_loan_value: number;
  total_interest: number;
  first_payment: number;
}

export interface DividebuySubsidy {
  minSubsidy?: number | null;
  practiceSubsidy?: string;
  term?: number;
  type: InstalmentType;
}

export type SoftSearchLink = {
  link: string;
};

export type FetchError = {
  status: number;
  message: string;
  code: string;
};

export type PatientFinanceRedirect = {
  redirectUrl: string;
};

export type PatientFinanceManual = {
  patient: {
    firstName: string;
    lastName: string;
    email: string;
    address: PatientAddress;
  };
  journey: {
    name: string;
    price: number;
  };
  orderId: string;
  practice: {
    id: string;
    name: string;
  };
};

export type PatientOrderStatusArgs = {
  splashKey: string;
};

export enum OrdertStatus {
  SUCCESS = 'success',
  REJECTED = 'rejected',
  PENDING = 'pending',
  CANCELLED = 'cancelled',
}

export type PatientOrderStatus = {
  status: OrdertStatus;
};

export type TreatmentStep = {
  title: string;
  subtitle?: string;
  description: string;
  carousel: TreatmentSlide[];
  imageUrl?: string;
  order: string;
};
export type TreatmentStepsBlock = {
  title: string;
  description: string;
  items: TreatmentStep[];
};

type TreatmentSlide = {
  slide: string;
};

type TreatmentBenefit = {
  title: string;
  description: string;
};

type TreatmentFaq = {
  question: string;
  answer: string;
};

type TreatmentTestimonial = {
  text: string;
  name: string;
  location: string;
  imageUrl?: string;
};

type TreatmentStats = {
  title: string;
  detail?: string;
  disclaimer?: string;
};

export type TreatmentVideo = {
  title: string;
  duration: string;
  tag: string;
  url: string;
};

export type TreatmentStatus = 'Pre assessment' | 'Post assessment' | 'Accepted' | 'Declined';

export type Treatment = {
  treatmentDefinition: string;
  excludeTreatmentContent?: boolean;
  stages?: string[];
};

export interface TreatmentPlan {
  filename: string;
  originalFilename: string;
}

export interface fileSizes {
  mb: number;
  kb: number;
}
export interface Attachment {
  file?: File;
  s3Filename?: string;
  fileName: string;
  fileSize: number;
  fileType?: string;
  fileSizes?: fileSizes;
  error?: {
    message?: string;
  };
}
export interface AttachmentUpload {
  s3Filename?: string;
  fileName: string;
}

export interface MultiAttachmentUrl {
  treatmentGuideId: string;
  files: Attachment[];
}

export interface AttachmentSignedUrl {
  clientUrl: string;
  fileName: string;
  s3Filename?: string;
}
export interface MultiPresignedUrl {
  urls: AttachmentSignedUrl[];
}

export type AttachmentSuccess = object;

export interface AddTreatmentPlan {
  filename: string;
  originalFilename: string;
}

export interface MultiAddAttachments {
  files: AttachmentUpload[];
}

type TreatmentGuideDecision =
  | PatientDecision.IM_NOT_SURE
  | PatientDecision.ID_LIKE_TO_GOAHEAD
  | PatientDecision.IM_INTERESTED
  | Declined
  | 'Accepted - pay in practice'
  | 'Accepted finance';

export interface TreatmentGuide {
  id?: string;
  patientId?: string;
  practiceId: string;
  treatments: Treatment[];
  treatmentDefinition?: string;
  excludeTreatmentContent?: boolean;
  stages?: string[];
  practitionerId?: string;
  price?: number;
  deposit?: number;
  notes?: string;
  practitionerFirstName?: string;
  practitionerLastName?: string;
  status?: TreatmentStatus;
  paymentMethod?: PaymentMethod;
  finance?: boolean;
  trackingId?: string;
  practiceName?: string;
  notReadyReason?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  country_code?: string;
  smileQuizCompleted?: boolean;
  smileQuizSkipped?: boolean;
  createdAt?: string;
  lastLogin?: number;
  decision?: TreatmentGuideDecision;
  treatmentPlan?: TreatmentPlan;
  attachments?: Attachment[];
  confirmation?: boolean;
  reason?: string;
  isArchived?: boolean;
  enablePayment?: boolean;
  displayPricing?: boolean;
  displayNotes?: boolean;
  displayFile?: boolean;
  guidePractitioners?: string[];
  lastSent?: string;
  sesEventType?: string;
  creditResult?: string;
  orderDecision?: string;
  orderStatus?: string;
  fulfilledAt?: string;
}

export interface TreatmentGuideRes {
  treatmentGuide: TreatmentGuide;
}

type Declined = 'Declined';

export interface CreateOrderArgs {
  paymentMethod?: PaymentMethod;
  notReadyReason?: NotReadyReason;
  treatmentGuideId: string;
  decision?: TreatmentGuideDecision;
  reason?: NotGoingAheadReason;
}

export type PatientInviteFormPageNames = 'patientInvite' | 'treatmentGuide' | 'pricingDetails';
export type UpdateTreatmentGuidePageName = 'treatmentGuide' | 'pricingDetails';

export interface PatientResetMagicLinkArgs {
  email: string;
}

export interface TreatmentDecisionEventProperties {
  'Practice name'?: string;
  'Treatment name'?: string;
  'Treatment price'?: number;
}

export enum PatientDecision {
  ID_LIKE_TO_GOAHEAD = `I'd like to go ahead`,
  IM_NOT_SURE = "I'm not sure",
  I_DONT_WANT_TO_GO_AHEAD = `I don't want to go ahead`,
  IM_INTERESTED = `I'm interested`,
}

export enum NotGoingAheadReason {
  TOO_EXPENSIVE = 'The treatment is too expensive',
  CANT_AFFORD = `I can't afford it`,
  WASNT_ELIGIBLE = `I wasn't eligible for finance`,
  ANXIOUS = `I am anxious about treatment`,
  SOMETHING_ELSE = `Something else`,
}

export interface UpdateDecisionArgs {
  decision: PatientDecision | Declined;
  reason?: NotGoingAheadReason;
}

export interface UpdateConfirmationArgs {
  confirmation: boolean;
}

export interface CheckoutSessionArgs {
  connectedAccountId: string;
  planId?: string;
}

export interface CheckoutSession {
  clientSecret: string;
}

export interface UpdateStripePaymentStatusArgs {
  treatmentGuideId: string;
  session_id: string;
  connectedAccountId: string;
}

export enum PatientDecisionColumnValues {
  GUIDE_SENT = 'Guide sent',
  VIEWED = 'Viewed',
  INTERESTED = 'Interested',
  NOT_SURE = 'Not sure',
  FINANCE_REFUNDED = 'Finance refunded',
  FINANCE_PROCESSED = 'Finance processed',
  FINANCE_DECLINED = 'Finance declined',
  PAID_WITH_FINANCE = 'Finance accepted',
  PAID_VIA_PRACTI = 'Paid via practi',
  PAY_IN_PRACTICE = 'Pay in practice',
  DECLINED = 'Declined',
  NOT_DELIVERED = 'Not delivered',
}

// API response types

export enum UserRole {
  ADMIN = 'ADMIN',
  USER = 'USER',
}

export interface UserPractice {
  id: string;
  name: string;
  role: UserRole;
}

export interface User {
  id: string;
  firstName: string;
  lastName: string;
  practices: UserPractice[];
}

export interface PatchUserArgs {
  hasOnboarded: boolean;
}

export interface Practice {
  id: string;
  isDemo: boolean;
  isInternal: boolean;
  email: string;
  logoUrl?: string;
  name: string;
  offersFinance: boolean;
  phone: string;
  address: string;
  financeOnly: boolean;
}

export interface PracticePublic {
  logoUrl: string;
  email: string;
  phone: string;
  name: string;
  address: string;
  checkoutEnabled: boolean;
  plansEnabled: boolean;
}

export interface PostPracticeArgs {
  email: string;
  name: string;
  phone: string;
  address: string;
}

export interface PatchPracticeArgs extends PostPracticeArgs {
  practiceId: string;
}

export interface PracticeMember {
  userId: string;
  email: string;
  role: UserRole;
  notificationsEnabled: boolean;
  hasLoggedIn: boolean;
  hasOnboarded: boolean;
}

export interface GetPracticeMembers {
  data: PracticeMember[];
}

export interface PostPracticeMembers {
  data: {
    practiceId: string;
    userId: string;
    email: string;
    role: UserRole;
    notificationsEnabled: boolean;
  };
}

export interface PostPracticeMembersArgs {
  practiceId: string;
  email: string;
  role: UserRole;
  notificationsEnabled: boolean;
}

export interface PatchPracticeMembersArgs {
  practiceId: string;
  userId: string;
  notificationsEnabled?: boolean;
  resendInvite?: boolean;
  role?: UserRole;
  hasOnboarded?: boolean;
}

export interface DeletePracticeMembersArgs {
  practiceId: string;
  userId: string;
}

export interface DeletePracticeMembersArgs {
  practiceId: string;
  userId: string;
}

export interface IPractitioner {
  id: string;
  name: string;
  practiceId: string;
}

export interface GetPractitioners {
  data: IPractitioner[];
}

export interface PostPractitionersArgs {
  practiceId: string;
  name: string;
}

export interface PostPractitioners {
  data: {
    id: string;
    name: string;
    practiceId: string;
  };
}

export interface PatchPractitionersArgs {
  practitionerId: string;
  name: string;
}

export interface PostTreatmentGuides {
  data: {
    id: string;
  };
}

export interface ITreatment {
  treatmentDefinition: string;
  excludeTreatmentContent?: boolean;
  steps?: string[];
}

export enum ITreatmentGuideDecision {
  ACCEPTED = 'ACCEPTED',
  DECLINED = 'DECLINED',
  INTERESTED = 'INTERESTED',
  UNSURE = 'UNSURE',
}

export interface ITreatmentGuide {
  id: string;
  practiceId?: string;
  archived?: boolean;
  attachments?: IAttachment[];
  decision?: ITreatmentGuideDecision;
  deposit?: string | null;
  enablePayment?: boolean;
  displayFile?: boolean;
  displayNotes?: boolean;
  displayPricing?: boolean;
  email?: string;
  firstName?: string;
  lastName?: string;
  notes?: string;
  paymentMethod?: IPaymentMethod;
  phone?: string;
  practitioners?: string[];
  practitionerNames?: string[];
  price?: string | null;
  pricingType?: PricingType | null;
  reason?: string | null;
  treatments?: ITreatment[];
  displayIntroduction?: boolean;
  sesEventType?: string;
}
export interface PostTreatmentGuidesArgs {
  attachments?: IAttachment[];
  deposit?: string | null;
  displayFile?: boolean;
  displayNotes?: boolean;
  displayPricing?: boolean;
  email: string;
  firstName: string;
  lastName: string;
  phone?: string | null;
  notes?: string;
  practiceId: string;
  practitioners?: string[];
  price?: string | null;
  pricingType?: PricingType | null;
  treatments: Treatment[];
}

export type PatchTreatmentGuideArgs = Omit<Partial<ITreatmentGuide>, 'deposit' | 'phone'> & {
  id: string;
  resend?: boolean;
  reason?: string | null;
  deposit?: string | null;
  phone?: string | null;
};

export type ITreatmentStep = {
  title: string;
  id: string;
  description: string;
  imageUrl?: string;
  slides: string[];
};
export interface ITreatmentDefinition {
  id: string;
  name: string;
  stats?: TreatmentStats[];
  steps?: ITreatmentStep[];
  video?: TreatmentVideo;
  benefits?: { items: TreatmentBenefit[]; title: string };
  testimonials?: TreatmentTestimonial[];
  faqs?: { items: TreatmentFaq[] };
  image: {
    url: string;
  };
  description: string;
}

export interface GetTreatmentDefinitions {
  data: ITreatmentDefinition[];
}
export interface GetTreatmentDefinition {
  data: ITreatmentDefinition | ITreatmentDefinition[];
}

export interface GetAttachmentsArgs {
  serverFilename: string;
}

export interface PostAttachmentsArgs {
  contentType: string;
  file?: CurrentAttachment;
}

export interface AttachmentUrl {
  serverFilename?: string;
  url: string;
}
export interface PostAttachments {
  data: AttachmentUrl;
}

export interface CurrentAttachment {
  file?: File;
  fileType?: string;
  fileSize?: number;
  fileName?: string;
  serverFilename?: string;
  clientFilename?: string;
  url?: string;
}

export interface IAttachment {
  clientFilename?: string;
  serverFilename?: string;
}

export interface PatientOverviewTreatment {
  name: string;
}

export type RefetchPatientOverview = (filters: Omit<GetPatientOverviewArgs, 'practiceId'>, page?: number) => void;

export enum PatientAction {
  GUIDE_SENT = 'GUIDE_SENT',
  FOLLOW_UP_SENT = 'FOLLOW_UP_SENT',
  VIEWED = 'VIEWED',
  INTERESTED = 'INTERESTED',
  NOT_SURE = 'NOT_SURE',
  FINANCE_REFUNDED = 'FINANCE_REFUNDED',
  FINANCE_PROCESSED = 'FINANCE_PROCESSED',
  FINANCE_DECLINED = 'FINANCE_DECLINED',
  PAID_WITH_FINANCE = 'PAID_WITH_FINANCE',
  PAY_IN_PRACTICE = 'PAY_IN_PRACTICE',
  DECLINED = 'DECLINED',
  GUIDE_BOUNCED = 'GUIDE_BOUNCED',
  NOT_DELIVERED = 'NOT_DELIVERED',
}

export enum PlanAction {
  PENDING = 'PENDING',
  ACTIVE = 'ACTIVE',
  CANCELLED = 'CANCELLED',
}

export enum PaymentAction {
  PENDING = 'PENDING',
  PAID = 'PAID',
  FAILED = 'FAILED',
  UPCOMING = 'UPCOMING',
}

export interface GetPatientOverview {
  data: PatientOverview[];
  pagination: { nextPage: string };
}

export interface GetPatientOverviewArgs {
  practiceId: string;
  pageNumber?: number;
  patientAction?: PatientAction;
  practitioner?: string;
  archived?: boolean;
  sortField?: SortField;
  sortDirection?: SortDirection;
  page?: number;
  patientId?: string;
  view?: PatientTableView;
}

type SortField = 'treatments' | 'pricingType' | 'price' | 'price' | 'patientAction' | 'lastViewedAt' | 'createdAt';

export enum SortDirection {
  ASCENDING = 'ASCENDING',
  DESCENDING = 'DESCENDING',
}

export enum PricingType {
  ESTIMATED = 'ESTIMATED',
  FINAL = 'FINAL',
}

export enum PatientTableView {
  DEFAULT = 'DEFAULT',
  FINANCE = 'FINANCE',
}

export interface PatientOverview {
  treatmentGuideId: string;
  patientId: string;
  firstName: string;
  lastName: string;
  treatments: PatientOverviewTreatment[];
  pricingType?: PricingType;
  deposit?: string | null;
  price?: string | null;
  patientAction?: PatientAction;
  reason?: string | null;
  lastViewedAt?: string;
  createdAt: string;
  displayPricing?: boolean;
  fulfilledAt?: string;
  acceptedAt?: string;
  archived?: boolean;
  lastSentAt?: string;
  followUpEmailSentAt?: string;
  canEdit: boolean;
  email?: string;
  applicantName?: string;
  subsidyAmount?: number;
  loanAmount?: number;
  remittanceAmount?: number;
}

export interface GetPracticeInstalmentsArgs {
  amount: string;
  practiceId: string;
}

export interface GetPracticeInstalments {
  data: {
    terms: DivideBuyInstalment[];
    subsidy: DividebuySubsidy[];
  };
}

export enum Accessor {
  PRACTICE_MEMBER = 'PRACTICE_MEMBER',
  PATIENT = 'PATIENT',
}

export interface PostDividebuyRedirectArgs {
  treatmentGuideId: string;
  postcode: string;
}

export enum ErrorCode {
  ALREADY_EXISTS = 'ALREADY_EXISTS',
  PRACTITIONER_EXISTS_EXCEPTION = 'PractitionerExistsException',
}

export enum PatientTableState {
  NO_GUIDES_EXIST = 'NO_GUIDES_EXIST',
  NO_RESULTS = 'NO_RESULTS',
}

export interface Perk {
  description: string;
  order: number;
  quantity: number | null;
}

export interface PracticePlans {
  id: string;
  practiceId: string;
  title: string;
  description: string;
  stripeProductId: string;
  stripePriceId: string;
  price: number;
  perks: Perk[];
}

export interface PlanStartDateResponse {
  startDate: string;
}

export interface ModulusCheckResponse {
  data: { result: boolean };
}

export interface PostQuotationBody {
  treatmentGuideId: string;
  selectedInstalmentTerm: number;
  instalmentDate: number;
  customer: Customer;
}

export enum QuoteDecision {
  APPROVED = 'approved',
  DECLINED = 'declined',
}

interface QuoteDetails {
  order_amount: number;
  loan_amount: number;
  deposit: number;
  first_instalment_amount: number;
  total_instalments: number;
  total_payable_today: number;
  regular_instalment_amount: number;
}

export interface Quote {
  id: string;
  decision: QuoteDecision;
  checkout_url?: string;
  details?: QuoteDetails;
}

interface DividebuyQuotationResponse {
  status_code: number;
  message: string;
  data: {
    requested_quote: Quote;
    alternative_quotes: Quote[];
  };
}

export interface PostQuotationResponse {
  data: {
    quotesResponse: DividebuyQuotationResponse;
  };
}

interface Customer {
  title: string;
  firstName: string;
  lastName: string;
  dateOfBirth: string; // YYYY-MM-DD
  email: string;
  mobileNumber: string;
  annualSalary: number;
  incomeType: string;
  residentialStatus: string;
  rent?: number; // in £s
  address: Address;
  previousAddresses?: Address[];
}

export interface Address {
  addressLine1: string;
  addressLine2?: string;
  addressLine3?: string;
  town: string;
  postcode: string;
  moveInDate?: string;
}
export interface PostPlanSubscriptionArgs {
  patient: Patient;
  payer: Payer;
  subscription: Subscription;
}

export interface Patient {
  firstName: string;
  lastName: string;
}

export interface Payer {
  email: string;
  firstName: string;
  lastName: string;
  title: string;
  postCode: string;
  accountHolderName: string;
  accountNumber: string;
  sortCode: string;
  addressLine1: string;
  addressLine2?: string;
  town?: string;
}

export interface Subscription {
  startDate: string;
  termsConsentedAt: string;
  fullPlanId: string;
}

export interface PostSelectQuoteBody {
  quoteId: string;
}

export interface PostSelectQuoteResponse {
  data: {
    checkoutUrl: string;
  };
}

export interface GetSubscriptionOverview {
  data: SubscriptionOverview[];
  pagination: { nextPage: string };
}

export interface SubscriptionOverview {
  subscriptionId: string;
  planName: string;
  payerName: string;
  patientName: string;
  userEmail: string;
  planPrice: number;
  subscriptionStatus: PlanAction;
  subscriptionStartDate: string;
  subscriptionLastPaymentStatus: PaymentAction;
}

export interface GetSubscriptionOverviewArgs {
  practiceId: string;
}

export interface GetPayoutOverview {
  data: PayoutOverview[];
  pagination: { nextPage: string };
}
export interface PayoutOverview {
  collectionMonth: string;
  payoutDate: string;
  totalCollected: number;
  status: PaymentAction;
}

export interface GetPayoutOverviewArgs {
  practiceId: string;
}
