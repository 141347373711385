import { useNavigate } from 'react-router-dom';
import { Heading, Illustration, Paragraph } from '@library/components/atoms';
import PageContentWrapper from '@library/components/atoms/PageContentWrapper';
import { Button } from '@library/components/molecules';
import { FinanceApprovalBlock } from '@library/components/organisms/FinanceApprovalBlock/FinanceApprovalBlock';
import { t } from 'i18next';

import { Spinner } from '@shared/components/Spinner';
import { useGetPractice, useGetTreatmentGuide } from '@shared/data/practice/hooks';
import { Accessor, IPaymentMethod } from '@shared/data/types';
import { patientDashboardPage } from '@shared/utils/urls';

export const FinanceWelcomeBackPage = () => {
  const navigate = useNavigate();
  const treatmentGuideId = localStorage.getItem('treatmentGuideId') || '';
  const practiceId = treatmentGuideId.split(':')[0];

  const { data: practice, isLoading: practiceIsLoading } = useGetPractice(practiceId, Accessor.PATIENT);
  const { data: treatmentGuide, isLoading: treatmentGuideIsLoading } = useGetTreatmentGuide(
    treatmentGuideId,
    Accessor.PATIENT,
  );

  if (practiceIsLoading || treatmentGuideIsLoading) {
    return <Spinner centered />;
  }

  if (!practice?.data) {
    return null;
  }

  if (
    treatmentGuide?.data.paymentMethod === IPaymentMethod.FINANCE &&
    !localStorage.getItem(`has-seen-confirmation-${treatmentGuideId}`)
  ) {
    localStorage.setItem(`has-seen-confirmation-${treatmentGuideId}`, 'true');
  }

  const financeSuccess = treatmentGuide?.data.paymentMethod === IPaymentMethod.FINANCE;

  return (
    <PageContentWrapper>
      {financeSuccess ? (
        <FinanceApprovalBlock
          name={practice.data.name}
          email={practice.data.email}
          address={practice.data.address}
          phone={practice.data.phone}
        />
      ) : (
        <div className="flex flex-col items-center">
          <div className="mb-6">
            <Illustration size="small" type="confused1" />
          </div>
          <div className="flex flex-col gap-3 justify-center text-center">
            <Heading variant="h4">{t('patient.checkoutWelcomeBackPage.notFinalisedHeading')}</Heading>
            <Paragraph size="large" className="!text-foregroundNeutralSecondary">
              {t('patient.checkoutWelcomeBackPage.notFinalisedDescription')}
            </Paragraph>
          </div>
          <div className="mt-6 text-center">
            <Button
              onClick={() => navigate(patientDashboardPage)}
              appearance="subtle"
              mode="accent"
              size="medium"
              text="My guide"
            />
          </div>
        </div>
      )}
    </PageContentWrapper>
  );
};
