import { memo } from 'react';
import { Icon } from '@library/components/atoms';
import { Button, Label } from '@library/components/molecules';
import clsx from 'clsx';

import {
  baseClasses,
  getCompactSizes,
  getFileIcon,
  getFileIconColor,
  getFileStateText,
  UploadFileState,
} from '../Upload/utils';

export interface UploadFileProps {
  fileSize?: number;
  compact?: boolean;
  fileName?: string;
  state?: UploadFileState;
  handleOnRemove: (fileName: string) => void;
  error?: string;
  helperText?: string;
}

export const UploadFile = memo(
  ({ fileSize, fileName, helperText, state = 'success', error, compact = false, handleOnRemove }: UploadFileProps) => {
    const deleteText = !compact ? 'Delete' : '';
    const stateText = getFileStateText(state!, error!);
    const labelHelperText = fileSize! > 0 ? `${helperText} • ${stateText}` : stateText;

    return (
      <div
        data-testid={`upload-file-${fileName}`}
        className={clsx(baseClasses, getCompactSizes(compact), state === 'error' && '!border-foregroundErrorPrimary')}
      >
        <div className={clsx('flex flex-grow gap-4 items-center')}>
          <Icon
            testId="upload-file-icon"
            size={compact ? 'small' : 'medium'}
            color={getFileIconColor(state)}
            iconName={getFileIcon(state)}
            state={state}
            outlined
          />
          <Label
            size={compact ? 'small' : 'medium'}
            labelText={fileName}
            helperText={labelHelperText}
            appearance="tertiary"
          />
        </div>
        <Button
          testId={`remove-file-${fileName}`}
          onClick={() => handleOnRemove(fileName!)}
          mode="accent"
          size="small"
          appearance="ghost"
          leftIcon="delete"
          text={deleteText}
          iconColor="text-foregroundNeutralQuaternary"
        />
      </div>
    );
  },
);

UploadFile.displayName = 'UploadFile';
