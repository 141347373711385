import { t } from 'i18next';
import { DateTime } from 'luxon';
import * as z from 'zod';

import { phoneNumberValidation, validations } from '@shared/utils/validations';

import { baseAddressSchema } from '../PostcodeInput/validations';

export const incomeTypes = [
  'Employed full-time',
  'Employed part-time',
  'Self employed',
  'Pension',
  'Other',
  'Director of a limited company',
  'Unemployed',
  'Retired, not working',
] as const;

export const titles = ['Mr', 'Mrs', 'Miss', 'Ms', 'Mx', 'Dr', 'Prof', 'Rev'] as const;

export const ownershipStatuses = ['Yes, outright owner', 'Yes, with mortgage', 'No, renting', 'No, other'] as const;

export type QuotationApplicantionSchema = z.infer<typeof quotationApplicantionSchema>;

export const checkoutAddressSchema = baseAddressSchema.extend({
  moveInDate: z
    .string()
    .refine(
      (moveInDate) => {
        if (moveInDate) {
          const date = DateTime.fromFormat(moveInDate, 'yyyy-MM-dd');
          return date.isValid;
        }
        return false;
      },
      { message: t('patient.patientCheckoutPage.quotationForm.invalidDateError') },
    )
    .refine(
      (moveInDate) => {
        if (moveInDate) {
          const now = DateTime.now();
          const date = DateTime.fromFormat(moveInDate, 'yyyy-MM-dd');
          return !(date > now);
        }
        return false;
      },
      { message: t('patient.patientCheckoutPage.quotationForm.futureDateError') },
    ),
});

export const quotationApplicantionSchema = z
  .object({
    title: z.enum(titles, {
      message: 'Title is required',
    }),
    firstName: z.string().trim().min(2, {
      message: 'First name is required and must have a minimum of two characters',
    }),
    lastName: z.string().trim().min(2, {
      message: 'Last name is required and must have a minimum of two characters',
    }),
    email: validations.email(),
    dateOfBirth: z
      .string()
      .refine(
        (dateOfBirth) => {
          if (dateOfBirth) {
            const date = DateTime.fromFormat(dateOfBirth, 'yyyy-MM-dd');
            return date.isValid;
          }
          return false;
        },
        { message: t('patient.patientCheckoutPage.quotationForm.invalidDateError') },
      )
      .refine(
        (dateOfBirth) => {
          const date = DateTime.fromFormat(dateOfBirth, 'yyyy-MM-dd');
          const now = DateTime.now();
          const eighteenYearsAgo = now.minus({ years: 18 });
          return date <= eighteenYearsAgo;
        },
        {
          message: 'You must be at least 18 years old',
        },
      ),
    mobileNumber: phoneNumberValidation(false),
    instalmentDate: z
      .number()
      .int('Instalment day must be an integer.')
      .min(1, 'Instalment day must be at least 1.')
      .max(31, 'Instalment day must not exceed 31.'),
    incomeType: z.enum(incomeTypes, {
      message: 'Employment status is required',
    }),
    annualSalary: z.string().min(1, { message: 'Annual salary is required' }),
    rent: z.string().optional(),
    residentialStatus: z.enum(ownershipStatuses, {
      message: 'Housing status is required',
    }),
    addresses: z.array(checkoutAddressSchema).refine(
      (addresses) => {
        if (addresses.length > 0) {
          const addressOrderInvalid = addresses.every(({ moveInDate }, index) => {
            if (index === 0) return true;
            const recentAddress = DateTime.fromFormat(addresses[index - 1].moveInDate, 'yyyy-MM-dd');
            const previousAddress = DateTime.fromFormat(moveInDate, 'yyyy-MM-dd');
            return !(previousAddress > recentAddress);
          });
          return addressOrderInvalid;
        }
        return false;
      },
      { message: t('patient.patientCheckoutPage.quotationForm.addressOrderInvalid') },
    ),
    dobDay: z.string().optional(),
    dobMonth: z.string().optional(),
    dobYear: z.string().optional(),
  })
  .refine(
    ({ rent, residentialStatus }) =>
      residentialStatus === 'No, renting' || residentialStatus === 'Yes, with mortgage' ? rent : true,
    {
      message: 'Monthly housing costs are required',
      path: ['rent'],
    },
  );
