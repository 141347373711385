import { ChangeEvent, InputHTMLAttributes, memo, useEffect, useRef, useState } from 'react';
import { UseFormSetValue } from 'react-hook-form';
import { useSnackbar } from 'notistack';

import { TreatmentGuideFormSchema } from '@practice/components/Forms/TreatmentGuideForm';
import { Attachment } from '@shared/data/types';

import { UploadButton } from '../UploadButton';
import { UploadFile } from '../UploadFile';
import { FileSizeExceeded } from '../UploadFile/UploadFile.stories';

import { getFileSizes } from './utils';

export interface UploadProps extends InputHTMLAttributes<HTMLInputElement> {
  compact: boolean;
  setValue: UseFormSetValue<TreatmentGuideFormSchema>;
  existingAttachments?: Attachment[];
  attachments?: Attachment[];
}

export const Upload = memo(({ existingAttachments, compact, ...inputProps }: UploadProps) => {
  const [currentFiles, setCurrentFiles] = useState<Attachment[] | undefined>(existingAttachments);
  const [duplicates, setDuplicates] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const filesRef = useRef<HTMLInputElement>(null);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (duplicates && duplicates.length > 0) {
      handleDuplicateFile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [duplicates]);

  const removeCurrentFile = (fileName: string) => {
    const result = currentFiles?.filter((obj) => {
      return obj.fileName !== fileName;
    });
    setCurrentFiles(result);
  };

  const handleDuplicateFile = () => {
    enqueueSnackbar(`It looks like some of these files have already been added`, {
      autoHideDuration: 3000,
      anchorOrigin: { horizontal: 'center', vertical: 'top' },
      variant: 'notistack',
      testId: `file-upload-existing`,
      type: 'warning',
    });
    setDuplicates([]);
  };
  const handleAddFiles = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setIsLoading(true);
      for (let i = 0; i < event.target.files.length; i++) {
        const reader = new FileReader();
        const file = event.target.files[i];
        reader.onloadend = () => {
          const fileSizes = getFileSizes(file.size || 0);
          const fileSizeExceeded = Boolean(fileSizes.mb > 50);

          const newFile: Attachment = {
            file: file,
            fileName: file.name,
            fileType: file.type,
            fileSize: file.size,
            fileSizes: fileSizes,
            ...(fileSizeExceeded && {
              error: {
                message: fileSizeExceeded ? 'sizeExceeded' : undefined,
              },
            }),
          };

          if (
            !currentFiles?.some((obj) => {
              return obj.fileName === file.name;
            })
          ) {
            setCurrentFiles((prevValue) => {
              return [...(prevValue || []), newFile];
            });
          } else {
            setDuplicates((prevValue) => {
              return [...(prevValue || []), file.name];
            });
          }
        };
        if (file) {
          reader.readAsDataURL(file);
        }
      }
      const filesRefCurrent = filesRef.current! as HTMLInputElement;
      filesRefCurrent.value = '';
      setIsLoading(false);
    }
  };

  return (
    <div className="w-full max-w-[41rem] grid mx-auto gap-3 place-items-cener grid-cols-1 flex-col items-center">
      <label htmlFor="files">
        <input
          ref={filesRef}
          className="hidden"
          disabled={isLoading}
          id="files"
          type="file"
          multiple
          onChange={handleAddFiles}
          accept=".doc,.docx,.pdf,.jpeg,.png,.jpg"
          {...inputProps}
        />
        <UploadButton isLoading={isLoading} compact={compact} />
      </label>

      {currentFiles &&
        currentFiles.map((file) => (
          <UploadFile
            key={file.fileName}
            compact={compact}
            fileName={file.fileName}
            helperText={`${file.fileSize}Mb • ${FileSizeExceeded}`}
            state={file.error ? 'error' : 'success'}
            error={file.error?.message}
            handleOnRemove={removeCurrentFile}
          />
        ))}
    </div>
  );
});

Upload.displayName = 'Upload';
