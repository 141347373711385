import { FC } from 'react';
import { Icon, IconProps } from '@library/components/atoms';
import { Label, Tooltip } from '@library/components/molecules';
import { HeaderContext } from '@tanstack/react-table';
import clsx from 'clsx';

import { PatientOverview, PayoutOverview, SubscriptionOverview } from '@shared/data/types';

type SubscriptionTableHeaderProps = HeaderContext<SubscriptionOverview, unknown> & {
  tooltipText?: string;
};

type PatientTableHeaderProps = HeaderContext<PatientOverview, unknown> & {
  tooltipText?: string;
};

type PayoutTableHeaderProps = HeaderContext<PayoutOverview, unknown> & {
  tooltipText?: string;
};

type TableHeaderProps = SubscriptionTableHeaderProps | PatientTableHeaderProps | PayoutTableHeaderProps;
export const TableHeader: FC<TableHeaderProps> = ({ column, header, tooltipText }) => {
  const headerName = header.id;
  const sortable = column.getCanSort();
  const sortDirection = column.getIsSorted();
  let iconName: IconProps['iconName'] = 'sort';

  if (sortDirection === 'asc') iconName = 'sort-asc';
  if (sortDirection === 'desc') iconName = 'sort-dsc';

  return (
    <div
      className={clsx(
        'w-full border-b border-b-borderNeutralDefault p-3 flex items-center h-[2.75rem]',
        headerName && 'hover:bg-backgroundNeutralSoft',
        sortable && '!cursor-pointer',
      )}
      onClick={sortable ? column.getToggleSortingHandler() : undefined}
    >
      <div className="flex items-center gap-2">
        <span>
          <div className="flex items-center gap-1">
            <Label
              className={sortable ? '!cursor-pointer' : ''}
              data-testid={`headerName-${headerName}`}
              appearance="secondary"
              size="xSmall"
              labelText={headerName}
              labelStrong={false}
            />
            <Tooltip position="top" title={tooltipText} arrow>
              {tooltipText && <Icon iconName={'info'} size={'xSmall'} className="text-foregroundNeutralTertiary" />}
            </Tooltip>
          </div>
        </span>
        {sortable && (
          <Icon
            viewBox="0 0 16 16"
            testId={iconName}
            iconName={iconName}
            size="xSmall"
            color="text-foregroundNeutralQuaternary"
          />
        )}
      </div>
    </div>
  );
};
