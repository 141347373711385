import { DateTime } from 'luxon';

import {
  NotReadyReason,
  PatientAction,
  PatientDecisionColumnValues,
  PatientOverviewTreatment,
  PricingType,
} from '@shared/data/types';
import { currencyFormatter } from '@shared/utils/numberFormatter';

export const getTreatmentFieldValue = (treatments?: PatientOverviewTreatment[]) => {
  const numberOfTreatments = treatments?.length || 0;
  const hasMultipleTreatments = numberOfTreatments > 1;

  if (!numberOfTreatments) return 'no treatments';

  const firstTreatmentName = treatments?.[0].name;
  return `${firstTreatmentName}${hasMultipleTreatments ? ` (+${numberOfTreatments - 1} more)` : ''}`;
};

export const formatCreatedAtValue = (date: string | null) => {
  return date ? formatTimestamp(date) : '-';
};

export const formatProcessedAtValue = (date: string | null) => {
  return date ? formatTimestamp(date) : '-';
};

export const showLastViewedDate = (date: string | null) => {
  if (!date) return 'Not Viewed';
  const dateToFormat = DateTime.fromISO(date);
  const timestamp = dateToFormat.toMillis();

  const minute = 1000 * 60;
  const hour = minute * 60;
  const day = hour * 24;
  const month = day * 31;
  const year = month * 12;
  const currentDate = Date.now();
  const dateDiff = currentDate - timestamp;
  switch (true) {
    //less than a minute
    case dateDiff <= minute: {
      return 'Just now';
    }
    //less than an hour
    case dateDiff > minute && dateDiff < hour: {
      const minutes = Math.round(dateDiff / minute);
      return `${minutes}m ago`;
    }
    //less than a day
    case dateDiff >= hour && dateDiff < day: {
      const hours = Math.round(dateDiff / hour);
      return `${hours}h ago`;
    }
    //more than a day, less than a month
    case dateDiff >= day && dateDiff < month: {
      const days = Math.round(dateDiff / day);
      return `${days}d ago`;
    }
    //more than a month, less than a year
    case dateDiff >= month && dateDiff < year: {
      const months = Math.round(dateDiff / month);
      return `${months}mo ago`;
    }
    //more than a year
    case dateDiff >= year: {
      const years = Math.round(dateDiff / year);
      return `${years}y ago`;
    }
  }
};

export const getPricingFieldValue = (pricingType?: PricingType) => {
  if (!pricingType) return '-';
  if (pricingType === PricingType.ESTIMATED) return 'Estimated';
  return 'Final';
};

export const getDecisionFieldValue = (patientAction?: PatientAction) => {
  // if (data?.lastLogin && status === 'Accepted' && paymentMethod === PaymentMethod.PAY_VIA_PRACTI) {
  //   return PatientDecisionColumnValues.PAID_VIA_PRACTI
  // }
  if (patientAction === PatientAction.VIEWED) {
    return PatientDecisionColumnValues.VIEWED;
  }
  if (patientAction === PatientAction.INTERESTED) {
    return PatientDecisionColumnValues.INTERESTED;
  }
  if (patientAction === PatientAction.NOT_SURE) {
    return PatientDecisionColumnValues.NOT_SURE;
  }
  if (patientAction === PatientAction.FINANCE_REFUNDED) {
    return PatientDecisionColumnValues.FINANCE_REFUNDED;
  }
  if (patientAction === PatientAction.FINANCE_DECLINED) {
    return PatientDecisionColumnValues.FINANCE_DECLINED;
  }
  if (patientAction === PatientAction.PAID_WITH_FINANCE) {
    return PatientDecisionColumnValues.PAID_WITH_FINANCE;
  }
  if (patientAction === PatientAction.PAY_IN_PRACTICE) {
    return PatientDecisionColumnValues.PAY_IN_PRACTICE;
  }
  if (patientAction === PatientAction.GUIDE_BOUNCED) {
    return PatientDecisionColumnValues.NOT_DELIVERED;
  }
  if (patientAction === PatientAction.GUIDE_SENT) {
    return PatientDecisionColumnValues.GUIDE_SENT;
  }
  if (patientAction === PatientAction.DECLINED) {
    return PatientDecisionColumnValues.DECLINED;
  }
  if (patientAction === PatientAction.FINANCE_PROCESSED) {
    return PatientDecisionColumnValues.FINANCE_PROCESSED;
  }
  return '-';
};

export const getInsightFieldValue = (notReadyReason?: string): string | null => {
  switch (notReadyReason) {
    case NotReadyReason.ANXIOUS_ABOUT_TREATMENT:
      return 'anxious about treatment';
    case NotReadyReason.NEED_MORE_INFORMATION:
      return 'need more information';
    case NotReadyReason.NOT_ELIGIBLE_FOR_FINANCE:
      return 'not eligible for finance';
    case NotReadyReason.PRICE_TOO_HIGH:
      return 'price too high';
    default:
      return null;
  }
};

export const getPriceFieldValue = (price?: string | null, pricingType?: PricingType, displayPricing?: boolean) => {
  if (!pricingType || !displayPricing) return '-';
  return price || '-';
};

export const showPriceFieldValue = (price: number) => {
  return price && price > 0 ? currencyFormatter(price) : '-';
};

const formatTimestamp = (date: string) => {
  // Parse the input timestamp
  const parsedDateTime = DateTime.fromISO(date);

  // Format the DateTime as '1 Feb 24'
  const formattedDateTime = parsedDateTime.toFormat('d LLL yy');
  return formattedDateTime;
};

export const numberComparator = (valueA: number, valueB: number) => {
  if (valueA === undefined && valueB === undefined) return 0;
  if (valueA === undefined) return -1;
  if (valueB === undefined) return 1;

  if (valueA == valueB) return 0;
  return valueA > valueB ? 1 : -1;
};

export const PATIENT_TABLE_HEADER_HEIGHT = 36;
export const NEW_PATIENT_TABLE_HEADER_HEIGHT = 46;
export const PATIENT_TABLE_CELL_HEIGHT = 64;
export const NEW_PATIENT_TABLE_CELL_HEIGHT = 72;
