import { memo } from 'react';
import { Paragraph } from '@library/components/atoms';
import { Pagination } from '@library/components/molecules';
import { flexRender } from '@tanstack/react-table';
import { Table } from '@tanstack/table-core';
import clsx from 'clsx';
import { t } from 'i18next';

import { Spinner } from '@shared/components/Spinner';

export interface TableProps {
  table: Table<unknown>;
  displayedRowCount: number;
  isEmpty?: boolean;
  noResults?: boolean;
  setPageIndex: (num: number) => void;
  loading?: boolean;
  emptyState?: React.ReactNode;
  filterResults?: boolean;
}

export const DataTable = memo(
  ({ table, displayedRowCount, isEmpty, setPageIndex, loading, emptyState, noResults }: TableProps) => {
    return (
      <>
        {loading ? (
          <div className="w-full flex justify-center mt-64">
            <Spinner />
          </div>
        ) : (
          <>
            {!loading && isEmpty ? (
              emptyState
            ) : (
              <>
                <div
                  className={clsx('guide-table', displayedRowCount && 'min-h-[34.5rem]')}
                  data-testid="treatment-guide-table"
                >
                  <table className="w-full">
                    <thead>
                      {table.getHeaderGroups().map((headerGroup) => (
                        <tr key={headerGroup.id} id="table-header">
                          {headerGroup.headers.map((header) => (
                            <th key={header.id} id={`table-col-${header.id}`}>
                              {flexRender(header.column.columnDef.header, header.getContext())}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody>
                      {table.getRowModel().rows.map((row) => (
                        <tr key={row.id} id={`table-row-${row.id}`}>
                          {row.getVisibleCells().map((cell) => (
                            <td key={cell.id} id={`table-cell-${cell.id}`}>
                              {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                {!!displayedRowCount && (
                  <div className="mb-36">
                    <Pagination
                      totalRecords={displayedRowCount}
                      className="mt-10"
                      pageNumbers
                      size="small"
                      recordsPerPage={7}
                      onSetCurrentPage={(num: number) => {
                        setPageIndex(num - 1);
                      }}
                    />
                  </div>
                )}

                {noResults && (
                  <Paragraph className="mt-10 !text-foregroundNeutralSecondary" size="medium">
                    {t('practice.patientDashboard.noResults')}
                  </Paragraph>
                )}
              </>
            )}
          </>
        )}
      </>
    );
  },
);

DataTable.displayName = 'DataTable';
