import { Meta, StoryObj } from '@storybook/react';

import { UploadFile } from './UploadFile';

const meta: Meta<typeof UploadFile> = {
  title: 'Library/Organisms/UploadFile',
  component: UploadFile,
  argTypes: {},
  tags: ['autodocs'],
};

meta.args = {
  compact: false,
  state: 'success',
  fileName: 'your_file_name.pdf',
};

export default meta;

type Story = StoryObj<typeof UploadFile>;

export const Default: Story = {};

export const IsCompact: Story = {};
IsCompact.args = { compact: true };

export const Error: Story = {};
Error.args = { state: 'error' };

export const FileSizeExceeded: Story = {};
FileSizeExceeded.args = { state: 'error', error: 'sizeExceeded' };
