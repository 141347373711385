import { InputHTMLAttributes, ReactNode, useRef } from 'react';
import { BadgeAppearance } from '@library/components/atoms';
import { Label } from '@library/components/molecules';
import { Size } from '@library/types';
import { transitionClasses } from '@library/utils';
import clsx from 'clsx';

import {
  baseClasses,
  baseDotClasses,
  getDisabledDotClasses,
  getDotSizeClasses,
  getHoverClasses,
  getSizeClasses,
  getStateClasses,
} from './utils';

export interface RadioProps extends InputHTMLAttributes<HTMLInputElement> {
  labelBadge?: string;
  name: string;
  value?: string;
  checked?: boolean;
  labelText?: string;
  helperText?: string;
  labelLeft?: boolean;
  radioSize?: Extract<Size, 'small' | 'medium' | 'large'>;
  labelStrong?: boolean;
  /** content that will be displayed when radio is checked */
  checkedContent?: ReactNode;
  /** applies a border to the radio component */
  border?: boolean;
  labelBadgeAppearance?: BadgeAppearance;
  hideRadio?: boolean;
}

export const Radio = ({
  radioSize = 'medium',
  labelStrong,
  name,
  value,
  labelText,
  helperText,
  labelLeft,
  disabled,
  checkedContent,
  border,
  labelBadge,
  labelBadgeAppearance,
  hideRadio,
  ...props
}: RadioProps) => {
  const ref = useRef(null);
  return (
    <label
      data-testid={`radio-label-${name}-${value}`}
      className={clsx(
        border && 'border border-foregroundNeutralDefault border-solid rounded-2xl p-5',
        !hideRadio && 'cursor-pointer',
      )}
    >
      <div
        className={clsx(labelLeft ? 'gap-4 justify-between' : 'gap-3 justify-start', 'inline-flex items-start w-full')}
      >
        {labelText && (
          <Label
            htmlFor={props.id}
            size={radioSize}
            labelStrong={labelStrong}
            labelText={labelText}
            helperText={helperText}
            labelBadge={labelBadge}
            labelBadgeAppearance={labelBadgeAppearance}
            appearance="secondary"
            className={clsx(labelLeft ? 'order-first' : 'order-last', 'flex flex-col gap-1')}
            element="div"
          />
        )}
        {!hideRadio && (
          <>
            <input
              ref={ref}
              value={value!}
              role="radio"
              type="radio"
              className="hidden"
              disabled={disabled}
              {...props}
            />
            <div className="p-[0.125rem]">
              <span
                className={clsx(
                  baseClasses,
                  transitionClasses,
                  getSizeClasses(radioSize),
                  getStateClasses(props.defaultChecked || props.checked!, disabled!),
                  getHoverClasses(props.defaultChecked || props.checked),
                )}
                data-testid={`radio`}
              >
                <span
                  data-testid="radio-dot"
                  className={clsx(
                    baseDotClasses,
                    getDotSizeClasses(radioSize),
                    getDisabledDotClasses(disabled),
                    transitionClasses,
                  )}
                ></span>
              </span>
            </div>
          </>
        )}
      </div>
      {props.checked && !hideRadio && <div className="mt-5">{checkedContent}</div>}
    </label>
  );
};

Radio.displayName = 'Radio';
