import { PropsWithChildren } from 'react';
import clsx from 'clsx';

export const TableCell = ({
  children,
  size = 'large',
  className,
}: PropsWithChildren<{ size?: 'medium' | 'large'; className?: string }>) => (
  <div
    className={clsx(
      'p-3 w-full border-b border-b-borderNeutralDefault hover:bg-backgroundNeutralSoft flex items-center',
      'text-[0.875rem] font-normal leading-5 text-foregroundNeutralPrimary',
      size === 'large' ? 'h-[4.5rem]' : 'h-[14]',
      className,
    )}
  >
    {children}
  </div>
);
