import { create } from 'zustand';

interface DividebuyLoanApplication {
  /** e.g. 0, 6, 12, 24, 36 */
  term?: number;
  /** e.g. 12.9 */
  interestRate?: number;
}

export interface DividebuyLoanApplicationAction {
  setData: (data: Partial<DividebuyLoanApplication>) => void;
}

export const initialStoreState = { term: undefined, interestRate: undefined };

export const useDividebuyLoanApplicationStore = create<DividebuyLoanApplication & DividebuyLoanApplicationAction>(
  (set) => ({
    ...initialStoreState,
    setData: (data) => set(() => data),
  }),
);
