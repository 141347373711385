import { CSSProperties, memo } from 'react';
type SpinnerPositions = {
  centered?: boolean;
  inButton?: boolean;
};

export const SimpleSpinner = () => (
  <div data-testid="spinner" className="lds-ellipsis" aria-label="loading...">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
);

export const Spinner = memo(({ centered = true, inButton }: SpinnerPositions) => {
  const props = {
    ...(centered && { style: { position: 'absolute', left: '50%', top: '50%' } }),
    ...(inButton && { style: { margin: '0 1rem' } }),
  } as { style: CSSProperties };

  return centered || inButton ? (
    <div {...props}>
      <div className="relative right-[50%]">
        <SimpleSpinner />
      </div>
    </div>
  ) : (
    <SimpleSpinner />
  );
});

Spinner.displayName = 'Spinner';
