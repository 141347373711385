import { memo } from 'react';
import { DataTable } from '@library/components/molecules/DataTable/DataTable';
import { Empty } from '@library/components/organisms';
import { Table } from '@tanstack/react-table';
import { t } from 'i18next';

import { GetSubscriptionOverviewArgs, SubscriptionOverview } from '@shared/data/types';

import { useTable } from './useTable';

export interface SubscriptionTableProps {
  subscriptions: SubscriptionOverview[];
  subscriptionsLoading: boolean;
  refetchSubscriptionOverview: (filters: Omit<GetSubscriptionOverviewArgs, 'practiceId'>) => void;
  practiceName: string;
  handleDownloadCsv?: (filters: Omit<GetSubscriptionOverviewArgs, 'practiceId'>) => void;
}

export const SubscriptionTable = memo(
  ({ subscriptions, subscriptionsLoading, refetchSubscriptionOverview, practiceName }: SubscriptionTableProps) => {
    const { setPageIndex, table } = useTable({
      subscriptionOverview: subscriptions,
      practiceName,
      refetchSubscriptionOverview,
    });

    const displayedRowCount = table.getFilteredRowModel().rows.length;

    const noSubscriptionsExist = !subscriptionsLoading && !displayedRowCount;

    return (
      <div data-testid="subscription-table-wrapper" className="w-full">
        <DataTable
          table={table as Table<unknown>}
          displayedRowCount={displayedRowCount}
          setPageIndex={setPageIndex}
          isEmpty={noSubscriptionsExist && !subscriptionsLoading}
          loading={subscriptionsLoading}
          emptyState={
            <>
              <style>{`.guide-table {display: none}`}</style>
              <Empty
                background
                description={t('practice.plansDashboard.patients.emptyState')}
                illustration="confused1"
                size="large"
                title={t('practice.plansDashboard.patients.emptyTitle')}
              />
            </>
          }
        />
      </div>
    );
  },
);

SubscriptionTable.displayName = 'SubscriptionTable';
