import { memo } from 'react';
import { Icon } from '@library/components/atoms';
import { Button, Label } from '@library/components/molecules';
import clsx from 'clsx';

import { baseClasses, getCompactSizes } from '../Upload/utils';

export interface UploadButtonProps {
  compact: boolean;
  isLoading: boolean;
}

export const UploadButton = memo(({ isLoading, compact }: UploadButtonProps) => {
  const labelHelperText = 'DOC, DOCX, PDF, JPEG, JPG and PNG up to 50MB each.';
  const labelText = 'Upload your file';
  const buttonText = 'Upload';
  return (
    <div
      data-testid="upload-button"
      className={clsx(
        baseClasses,
        isLoading && '!cursor-not-allowed',
        ' cursor-pointer hover:border-borderNeutralDefaultHover',
        getCompactSizes(compact),
      )}
    >
      <div className={clsx('pointer-events-none flex flex-grow gap-4 items-center')}>
        <Icon size={compact ? 'small' : 'medium'} color="text-foregroundNeutralSecondary" iconName="upload" outlined />
        <Label
          size={compact ? 'small' : 'medium'}
          labelText={labelText}
          helperText={labelHelperText}
          appearance="tertiary"
        />
      </div>
      <Button
        className="pointer-events-none hover:bg-backgroundBlueSoftHover active:bg-backgroundBlueSoftPressed"
        type="button"
        mode="accent"
        appearance="subtle"
        size="small"
        text={buttonText}
      />
    </div>
  );
});

UploadButton.displayName = 'UploadButton';
