import { CellContext } from '@tanstack/react-table';
import { t } from 'i18next';

import { PaymentAction, PayoutOverview } from '@shared/data/types';

import { CreateDecisionTableCell } from '../../../PatientTable/CellRenderers/DecisionRenderer/DecisionRenderer';

const getTooltipText = (status?: PaymentAction) => {
  switch (status) {
    case PaymentAction.PAID:
      return t('practice.plansDashboard.statusTooltip.paid');
    case PaymentAction.UPCOMING:
      return t('practice.plansDashboard.statusTooltip.upcoming');
    default:
      return '';
  }
};

export const PayoutStatusRenderer = (props: CellContext<PayoutOverview, PaymentAction>) => {
  const { status } = props.row.original;
  const tooltipText = getTooltipText(status);
  return CreateDecisionTableCell(status, tooltipText);
};
