import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';
import { t } from 'i18next';

import { Icon } from '@shared/components/Icon';
import { Typography } from '@shared/components/Typography';
import { useAuth } from '@shared/stores/authStore';
import { routePaths } from '@shared/utils/urls';

const iconContainerStyles = `
  flex
  justify-start
`;

const mobileContentStyles = `
  transition
  duration-500
  flex
  flex-col
  items-start
  h-[calc(100vh-64px)]
  pb-64
  pt-6
  justify-between
  bg-primary-contrast
  top-16
  fixed
  left-0
  right-0
`;
type Props = {
  logoUrl: string;
};

export const MobileNavigation = ({ logoUrl }: Props) => {
  const [active, setActive] = useState(false);
  const { logout } = useAuth();
  const handleMobileContent = () => setActive((prev) => !prev);

  return (
    <div data-testid="mobile-navigation" className="bg-primary-contrast">
      <header className="bg-primary-contrast flex items-center justify-between fixed w-full top-0 p-2 px-3 z-[99999]">
        <NavLink
          onClick={() => setActive(false)}
          to={`/${routePaths.patient}/${routePaths.dashboard}/${routePaths.account}`}
          className="flex items-center"
        >
          <Icon name="user" className="stroke-primary-dark" />
        </NavLink>
        <NavLink to={`/${routePaths.patient}/${routePaths.dashboard}`}>
          <img
            src={logoUrl}
            alt="logo"
            className="max-h-[57px]"
            onError={({ currentTarget }) => {
              currentTarget.src = '/images/patient/logo.svg';
            }}
          />
        </NavLink>
        <div className={iconContainerStyles} onClick={handleMobileContent}>
          {active ? (
            <Icon
              name="x"
              className="stroke-primary-dark"
              size={30}
              title={t('patient.navigation.ariaIconCloseLabel')}
            />
          ) : (
            <Icon
              name="menu"
              size={30}
              className="stroke-primary-dark"
              title={t('patient.navigation.ariaIconOpenLabel')}
            />
          )}
        </div>
      </header>
      <div className={clsx(mobileContentStyles, active ? 'translate-x-0' : '-translate-x-full', 'z-[999999]')}>
        <nav className="h-full">
          <ul className="flex flex-col gap-8 text-left h-full">
            <li>
              <NavLink
                to={`/${routePaths.patient}/${routePaths.dashboard}`}
                className="p-4"
                onClick={handleMobileContent}
              >
                <Typography variant="h3" element="span" className="text-primary-main">
                  {t('patient.navigation.myJourney')}
                </Typography>
              </NavLink>
            </li>
            <li>
              <NavLink
                to={`/${routePaths.patient}/${routePaths.dashboard}/${routePaths.support}`}
                className="p-4"
                onClick={handleMobileContent}
              >
                <Typography variant="h3" element="span" className="text-primary-dark">
                  {t('patient.navigation.support')}
                </Typography>
              </NavLink>
            </li>
          </ul>
          <ul>
            <li className="justify-self-end">
              <NavLink
                to={`/${routePaths.patient}/${routePaths.login}`}
                onClick={logout}
                className="flex items-center gap-2 p-4"
              >
                <Icon name="arrow-right" className="stroke-primary-dark" />
                <Typography variant="b16" element="span" className="text-primary-dark">
                  {t('patient.navigation.logout')}
                </Typography>
              </NavLink>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};
