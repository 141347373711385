export type UploadFileState = 'error' | 'success';

export const getFileSizes = (bytes: number) => {
  return {
    kb: parseFloat((bytes / 1000).toFixed(1)),
    mb: parseFloat((bytes / 1000000).toFixed(1)),
  };
};

export const baseClasses = `
  w-full flex place-items-center justify-center rounded-2xl border border-borderNeutralSoft
`;

export const getCompactSizes = (compact: boolean) => {
  switch (compact) {
    case true:
      return 'gap-4 p-4';
    case false:
      return 'gap-6 p-6';
  }
};

export const getFileStateText = (state: UploadFileState, error?: string) => {
  switch (state) {
    case 'success':
      return 'Complete';
    case 'error':
      switch (error) {
        case 'sizeExceeded':
          return 'The file size is too large';
        default:
          return 'Try again';
      }
  }
};

export const getFileIcon = (state: UploadFileState) => {
  switch (state) {
    case 'success':
      return 'check';
    case 'error':
      return 'info';
  }
};

export const getFileIconColor = (state?: UploadFileState) => {
  switch (state) {
    case 'success':
      return 'text-foregroundSuccessPrimary';
    case 'error':
      return 'text-foregroundErrorPrimary';
    default:
      return 'text-foregroundNeutralSecondary';
  }
};
