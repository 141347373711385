import { useEffect, useMemo } from 'react';
import { Navigate } from 'react-router-dom';
import { Heading, Paragraph } from '@library/components/atoms';
import PageContentWrapper from '@library/components/atoms/PageContentWrapper';
import DividebuyEligibilityResults from '@library/components/organisms/DividebuyEligibilityResults';
import QuotationApiForm from '@library/components/organisms/QuotationApiForm';
import { scrollToTop } from '@library/utils';
import { t } from 'i18next';

import { Spinner } from '@shared/components/Spinner';
import {
  useGetPracticeInstalments,
  useGetTreatmentGuide,
  usePostDividebuyQuotation,
  usePostDividebuySelectQuote,
} from '@shared/data/practice/hooks';
import { Accessor, PostQuotationBody } from '@shared/data/types';
import { useDividebuyLoanApplicationStore } from '@shared/stores/dividebuyLoanApplicationStore';
import { patientDashboardPage } from '@shared/utils/urls';

export const DividebuyLoanApplication = () => {
  const treatmentGuideId = localStorage.getItem('treatmentGuideId') || '';
  const practiceId = treatmentGuideId.split(':')[0];

  const { term } = useDividebuyLoanApplicationStore();
  const {
    data: postQuotationResponse,
    isLoading: postQuotationIsLoading,
    mutate: postQuotation,
  } = usePostDividebuyQuotation();

  const { isLoading: postSelectQuoteIsLoading, mutate: postSelectQuote } = usePostDividebuySelectQuote();

  const onContinueToDividebuy = (quoteId: string) => {
    postSelectQuote(
      { quoteId },
      {
        onSuccess: (res) => window.location.replace(res.data.checkoutUrl),
      },
    );
  };

  const onSubmitForm = (body: PostQuotationBody) => {
    scrollToTop();
    postQuotation(body);
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  const { data: treatmentGuide, isLoading: treatmentGuideIsLoading } = useGetTreatmentGuide(
    treatmentGuideId,
    Accessor.PATIENT,
  );

  const finalBalance = parseInt(treatmentGuide?.data?.price || '0') - parseInt(treatmentGuide?.data?.deposit || '0');

  const {
    data: instalments,
    isLoading,
    refetch: getInstalments,
  } = useGetPracticeInstalments({
    amount: String(finalBalance / 100),
    practiceId,
  });

  useEffect(() => {
    if (finalBalance > 0 && practiceId) getInstalments();
  }, [finalBalance, practiceId, getInstalments]);

  const instalmentsData = useMemo(() => instalments?.data.terms || [], [instalments?.data]);

  const availableInstalments = useMemo(
    () => instalmentsData.filter(({ available, type }) => type !== 'representative_example' && available != false),
    [instalmentsData],
  );

  if (postQuotationIsLoading) {
    return (
      <PageContentWrapper>
        <div className="flex flex-col items-center text-center min-h-[calc(100vh-7.375rem)]">
          <Spinner centered={false} />
          <Heading className="mt-9" variant="h4">
            {t('patient.patientCheckoutPage.quotationForm.loadingTitle')}
          </Heading>
          <Paragraph size="large" className="!text-foregroundNeutralSecondary mt-3">
            {t('patient.patientCheckoutPage.quotationForm.loadingDescription')}
          </Paragraph>
        </div>
      </PageContentWrapper>
    );
  }

  if (treatmentGuideIsLoading || isLoading) return <Spinner />;

  if (postQuotationResponse) {
    return (
      <PageContentWrapper>
        <DividebuyEligibilityResults
          instalmentsData={availableInstalments}
          requestedQuote={postQuotationResponse?.data?.quotesResponse?.data?.requested_quote}
          alternativeQuotes={postQuotationResponse?.data?.quotesResponse?.data?.alternative_quotes}
          onContinueToDividebuy={onContinueToDividebuy}
          postSelectQuoteIsLoading={postSelectQuoteIsLoading}
        />
      </PageContentWrapper>
    );
  }

  return (
    <PageContentWrapper>
      {treatmentGuideId && term ? (
        <QuotationApiForm term={term} treatmentGuideId={treatmentGuideId} onSubmitForm={onSubmitForm} />
      ) : (
        <Navigate to={patientDashboardPage} />
      )}
    </PageContentWrapper>
  );
};
