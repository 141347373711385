import { memo, RefObject, useEffect, useState } from 'react';
import { Controller, FieldValues, Path, PathValue, UseFormReturn } from 'react-hook-form';
import { Button, Input, Selector } from '@library/components/molecules';
import { t } from 'i18next';
import { z } from 'zod';

import { validations } from '@shared/utils/validations';

import { QuotationApplicantionSchema } from '../QuotationApiForm/validations';

import { addressSchema } from './validations';

export type AddressFormSchema = z.infer<typeof addressSchema>;
export type Address = {
  addressline1: string;
  addressline2: string;
  posttown: string;
  postcode: string;
};

type PostcodeInputProps<T extends FieldValues> = {
  formRef?: RefObject<HTMLFormElement>;
  formProps: UseFormReturn<T>;
  searchByPostcode: (postcode: string) => void;
  addressOptions: Address[];
  disableForm?: boolean;
  postcodeError: string | null;
  placeholder?: string;
  fieldNamePrefix?: string;
  addressLine3?: boolean;
  postcodeFinderError?: string;
};

export const PostcodeInputInner = <T extends AddressFormSchema | QuotationApplicantionSchema>({
  formProps,
  searchByPostcode,
  addressOptions,
  disableForm,
  postcodeError,
  placeholder,
  fieldNamePrefix = '',
  addressLine3,
  postcodeFinderError,
}: PostcodeInputProps<T>) => {
  const [selectedAddress, setSelectedAddress] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [postcodeValidation, setPostcodeValidation] = useState<string | null>(null);

  useEffect(() => {
    searchByPostcode(searchTerm);
    formProps.clearErrors();
    if (searchTerm.length >= 6 && !validations.postcode.safeParse(searchTerm).success) {
      setPostcodeValidation(t('plans.patientPayerInfo.billingInfo.error.postcode'));
    } else {
      setPostcodeValidation(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  const Wrapper = disableForm ? 'div' : 'form';

  return (
    <Wrapper
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <div className="w-full flex flex-col gap-6">
        <div>
          <Selector
            options={addressOptions.map((address) => {
              return {
                name: `${address.addressline1}, ${address.addressline2 ? address.addressline2 + ',' : ''} ${
                  address.posttown
                }, ${address.postcode}`,
                value: `${address.addressline1}, ${address.addressline2}, ${address.posttown}, ${address.postcode}`,
              };
            })}
            size={'large'}
            onChange={(selection) => {
              const address = addressOptions.find(
                (option) =>
                  `${option.addressline1}, ${option.addressline2 ? option.addressline2 + ',' : ''} ${
                    option.posttown
                  }, ${option.postcode}` === selection.name,
              );
              setSelectedAddress(true);
              formProps.setValue(
                `${fieldNamePrefix}addressline1` as Path<T>,
                (address?.addressline1 || '') as PathValue<T, Path<T>>,
              );
              formProps.setValue(
                `${fieldNamePrefix}addressline2` as Path<T>,
                (address?.addressline2 || '') as PathValue<T, Path<T>>,
              );
              if (addressLine3) {
                formProps.setValue(`${fieldNamePrefix}addressline3` as Path<T>, '' as PathValue<T, Path<T>>);
              }

              formProps.setValue(
                `${fieldNamePrefix}posttown` as Path<T>,
                (address?.posttown || '') as PathValue<T, Path<T>>,
              );

              formProps.setValue(
                `${fieldNamePrefix}postcode` as Path<T>,
                (address?.postcode || '') as PathValue<T, Path<T>>,
              );
              setSearchTerm('');
            }}
            setSearchTerm={setSearchTerm}
            searchTerm={searchTerm}
            validationError={postcodeError || postcodeValidation || postcodeFinderError || ''}
            labelText="Address finder"
            placeholder={placeholder}
            full
            hideArrowIcons
            errorDescription="Please enter a valid postcode"
            showMenu={addressOptions.length > 0}
            leftIcon="search"
            iconColour="text-foregroundNeutralTertiary"
          />
          {!selectedAddress && (
            <Button
              className="!p-0 !text-foregroundBrandPrimary !bg-transparent"
              appearance="ghost"
              mode="accent"
              size="small"
              text="Enter address manually"
              type="button"
              onClick={() => {
                setSelectedAddress(true);
                formProps.setValue(`${fieldNamePrefix}addressline1` as Path<T>, '' as PathValue<T, Path<T>>);
                formProps.setValue(`${fieldNamePrefix}addressline2` as Path<T>, '' as PathValue<T, Path<T>>);
                if (addressLine3) {
                  formProps.setValue(`${fieldNamePrefix}addressline3` as Path<T>, '' as PathValue<T, Path<T>>);
                }
                formProps.setValue(`${fieldNamePrefix}posttown` as Path<T>, '' as PathValue<T, Path<T>>);
                formProps.setValue(`${fieldNamePrefix}postcode` as Path<T>, '' as PathValue<T, Path<T>>);
              }}
            />
          )}
        </div>

        {selectedAddress && (
          <>
            <Controller
              name={`${fieldNamePrefix}addressline1` as Path<T>}
              control={formProps?.control}
              render={({ field, fieldState }) => (
                <Input
                  testId="line1"
                  inputSize="large"
                  type="text"
                  id="line1"
                  labelText="Line 1"
                  labelSize="small"
                  placeholder="123 High Street"
                  {...field}
                  value={(field.value as string) || ''}
                  errorText={fieldState.error?.message}
                  onBlur={() => {
                    formProps.trigger(`${fieldNamePrefix}addressline1` as Path<T>);
                  }}
                />
              )}
            />
            <Controller
              name={`${fieldNamePrefix}addressline2` as Path<T>}
              control={formProps?.control}
              render={({ field, fieldState }) => (
                <Input
                  testId="line2"
                  inputSize="large"
                  type="text"
                  id="line2"
                  labelText="Line 2 (optional)"
                  labelSize="small"
                  {...field}
                  errorText={fieldState.error?.message}
                  onBlur={() => {
                    formProps.trigger(`${fieldNamePrefix}addressline2` as Path<T>);
                  }}
                  value={(field.value as string) || ''}
                />
              )}
            />
            {
              <Controller
                name={`${fieldNamePrefix}addressline3` as Path<T>}
                control={formProps?.control}
                render={({ field, fieldState }) => (
                  <Input
                    testId="line3"
                    inputSize="large"
                    type="text"
                    id="line3"
                    labelText="Line 3 (optional)"
                    labelSize="small"
                    {...field}
                    errorText={fieldState.error?.message}
                    onBlur={() => {
                      formProps.trigger(`${fieldNamePrefix}addressline3` as Path<T>);
                    }}
                    value={(field.value as string) || ''}
                  />
                )}
              />
            }
            <Controller
              name={`${fieldNamePrefix}posttown` as Path<T>}
              control={formProps?.control}
              render={({ field, fieldState }) => (
                <Input
                  testId="town"
                  inputSize="large"
                  type="text"
                  id="town"
                  labelText="Town"
                  labelSize="small"
                  {...field}
                  value={(field.value as string) || ''}
                  errorText={fieldState.error?.message}
                  onBlur={() => {
                    formProps.trigger(`${fieldNamePrefix}posttown` as Path<T>);
                  }}
                />
              )}
            />
            <Controller
              name={`${fieldNamePrefix}postcode` as Path<T>}
              control={formProps?.control}
              render={({ field, fieldState }) => (
                <Input
                  testId="postcode"
                  inputSize="large"
                  type="text"
                  id="postcode"
                  labelText="Postcode"
                  labelSize="small"
                  placeholder="SW1A 1AA"
                  {...field}
                  errorText={fieldState.error?.message}
                  onBlur={() => {
                    formProps.trigger(`${fieldNamePrefix}postcode` as Path<T>);
                  }}
                  value={(field.value as string) || ''}
                />
              )}
            />
          </>
        )}
      </div>
    </Wrapper>
  );
};

export const PostcodeInput = memo(PostcodeInputInner) as <T extends FieldValues>(
  props: PostcodeInputProps<T>,
) => JSX.Element;
