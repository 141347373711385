import { useCallback, useState } from 'react';
import { TableCell } from '@library/components/atoms/TableCell';
import { BaseLabel, TableHeader, Tooltip } from '@library/components/molecules';
import {
  CellContext,
  ColumnDef,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  Row,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import clsx from 'clsx';
import { t } from 'i18next';

import {
  ActionRenderer,
  PatientRendererNewDS,
} from '@practice/pages/PatientsPages/PatientsDashboard/PatientTable/CellRenderers';
import { DecisionRendererNewDS } from '@practice/pages/PatientsPages/PatientsDashboard/PatientTable/CellRenderers/DecisionRenderer/DecisionRenderer';
import { TreatmentRendererNewDS } from '@practice/pages/PatientsPages/PatientsDashboard/PatientTable/CellRenderers/TreatmentRenderer/TreatmentRenderer';
import {
  formatCreatedAtValue,
  formatProcessedAtValue,
  getDecisionFieldValue,
  getPriceFieldValue,
  getTreatmentFieldValue,
  showLastViewedDate,
  showPriceFieldValue,
} from '@practice/pages/PatientsPages/PatientsDashboard/PatientTable/utils';
import { GetPatientOverviewArgs, PatientOverview, PatientTableView, PricingType } from '@shared/data/types';

type CellFn<T> = CellContext<PatientOverview, T>;

interface Props {
  patientOverview: PatientOverview[];
  practiceName: string;
  refetchPatientOverview: (filters: Omit<GetPatientOverviewArgs, 'practiceId'>) => void;
  patientTableView?: PatientTableView;
}

export const useTable = ({ patientOverview, practiceName, refetchPatientOverview, patientTableView }: Props) => {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [filter, setFilter] = useState('');
  const [pageIndex, setPageIndex] = useState(0);

  const globalFilterFn = useCallback((row: Row<PatientOverview>, _columnId: string, filterValue: string) => {
    const firstName = (row.getValue('Patient') as string) || '';
    const email = (row.getValue('email') as string) || '';

    return filterValue
      ? firstName.toLowerCase().includes(filterValue.toLowerCase()) ||
          email.toLowerCase().includes(filterValue.toLowerCase())
      : true;
  }, []);

  const columns: ColumnDef<PatientOverview>[] = [
    {
      id: 'Patient',
      accessorFn: (data: PatientOverview) => {
        return `${data.firstName} ${data.lastName}`;
      },
      cell: PatientRendererNewDS,
      size: 260,
    },
  ];

  if (patientTableView === PatientTableView.FINANCE) {
    columns.push({
      id: 'Applicant',
      accessorKey: 'applicantName',
      cell: (data: CellFn<string>) => <TableCell>{data.getValue()}</TableCell>,
      size: 260,
    });
  }

  columns.push({
    id: 'Treatment',
    accessorFn: (data: PatientOverview) => {
      return getTreatmentFieldValue(data?.treatments);
    },
    cell: TreatmentRendererNewDS,
    size: 560,
  });

  if (patientTableView === PatientTableView.FINANCE) {
    columns.push(
      {
        id: 'Loan',
        accessorKey: 'loanAmount',
        header: (props) => <TableHeader {...props} tooltipText={t('practice.patientDashboard.table.loanTooltip')} />,
        cell: (data: CellFn<number>) => <TableCell>{showPriceFieldValue(data.getValue())}</TableCell>,
        size: 260,
      },
      {
        id: 'Subsidy',
        accessorKey: 'subsidyAmount',
        header: (props) => <TableHeader {...props} tooltipText={t('practice.patientDashboard.table.subsidyTooltip')} />,
        cell: (data: CellFn<number>) => <TableCell>{showPriceFieldValue(data.getValue())}</TableCell>,
        size: 260,
      },
      {
        id: 'Net',
        accessorKey: 'remittanceAmount',
        header: (props) => <TableHeader {...props} tooltipText={t('practice.patientDashboard.table.netTooltip')} />,
        cell: (data: CellFn<number>) => <TableCell>{showPriceFieldValue(data.getValue())}</TableCell>,
        size: 260,
      },
    );
  } else {
    columns.push(
      {
        id: 'Price',
        size: 220,
        accessorFn: (data: PatientOverview) => {
          return getPriceFieldValue(data?.price, data?.pricingType, data?.displayPricing);
        },
        cell: (data: CellFn<number>) => (
          <TableCell>
            {data?.row.original?.deposit ? (
              <span>
                <Tooltip
                  position="top"
                  title={`In-practice deposit: ${showPriceFieldValue(parseFloat(data?.row.original?.deposit))}`}
                  arrow
                >
                  <BaseLabel
                    labelTextClassName="border-b border-dashed border-borderNeutralDefault"
                    badgeAppearance="none"
                    badgePosition="col"
                    badgeText={
                      data.row.original?.displayPricing && data.row.original?.pricingType === PricingType.ESTIMATED
                        ? 'Estimated'
                        : undefined
                    }
                    size="small"
                    text={showPriceFieldValue(data.getValue())}
                  />
                </Tooltip>
              </span>
            ) : (
              <BaseLabel
                badgeAppearance="none"
                badgePosition="col"
                badgeText={
                  data.row.original?.displayPricing && data.row.original?.pricingType === PricingType.ESTIMATED
                    ? 'Estimated'
                    : undefined
                }
                size="small"
                text={showPriceFieldValue(data.getValue())}
              />
            )}
          </TableCell>
        ),
      },
      {
        id: 'Created',
        accessorKey: 'createdAt',
        cell: (data: CellFn<string>) => <TableCell>{formatCreatedAtValue(data.getValue())}</TableCell>,
        size: 160,
      },
    );
  }

  columns.push({
    accessorKey: 'patientAction',
    id: 'Status',
    accessorFn: ({ patientAction }) => getDecisionFieldValue(patientAction),
    cell: DecisionRendererNewDS,
    size: 250,
  });

  if (patientTableView == PatientTableView.FINANCE) {
    columns.push({
      id: 'Processed',
      accessorKey: 'fulfilledAt',
      header: (props) => <TableHeader {...props} tooltipText={t('practice.patientDashboard.table.processedTooltip')} />,
      cell: (props: CellFn<string>) => <TableCell>{formatProcessedAtValue(props.getValue())}</TableCell>,
      size: 200,
    });
  }

  if (patientTableView != PatientTableView.FINANCE) {
    columns.push({
      id: 'Viewed',
      accessorFn: ({ lastViewedAt }) => lastViewedAt || null,
      accessorKey: 'lastViewedAt',
      cell: (props: CellFn<string>) => <TableCell>{showLastViewedDate(props.getValue())}</TableCell>,
      size: 200,
    });
  }

  columns.push(
    {
      id: ' ',
      cell: (props: CellFn<string>) => (
        <TableCell className={clsx('!px-0')}>
          <ActionRenderer
            practiceName={practiceName}
            data={props.row.original}
            refetchPatientOverview={refetchPatientOverview}
          />
        </TableCell>
      ),
    },
    {
      // Hidden email column to allow filtering by email
      accessorKey: 'email',
      enableHiding: true,
    },
  );

  const table = useReactTable({
    data: patientOverview,
    state: {
      sorting,
      globalFilter: filter,
      pagination: {
        pageSize: 7,
        pageIndex,
      },
      columnVisibility: {
        email: false,
      },
    },
    defaultColumn: {
      header: TableHeader,
    },
    globalFilterFn,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    columns,
  });

  return {
    table,
    setFilter,
    setSorting,
    setPageIndex,
  };
};
