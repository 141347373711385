import { useState } from 'react';
import { ErrorText } from '@library/components/atoms';
import { t } from 'i18next';
import { DateTime } from 'luxon';

import { useMediaQuery } from '@shared/hooks';

import { Label } from '../Label';
import { Option, Selector } from '../Selector';

const dayOptions = [...Array(31).keys()].map((i) => {
  let day = (i + 1).toString();
  if (i < 9) {
    day = '0' + day;
  }
  return { name: day, value: day };
}) as unknown as Option[];

const monthOptions = [
  { name: 'January', value: '01' },
  { name: 'February', value: '02' },
  { name: 'March', value: '03' },
  { name: 'April', value: '04' },
  { name: 'May', value: '05' },
  { name: 'June', value: '06' },
  { name: 'July', value: '07' },
  { name: 'August', value: '08' },
  { name: 'September', value: '09' },
  { name: 'October', value: '10' },
  { name: 'November', value: '11' },
  { name: 'December', value: '12' },
];

const currentYear = new Date().getFullYear();
const yearOptions = Array.from({ length: currentYear - (currentYear - 100) }, (_, i) => {
  const year = (currentYear - i).toString();
  return {
    name: year,
    value: year,
  };
}) as unknown as Option[];

interface Date {
  dobDay: string;
  dobMonth: string;
  dobYear: string;
}

const dateDropdowns: { name: keyof Date; options: Option[]; placeholder: string }[] = [
  {
    name: 'dobDay',
    options: dayOptions,
    placeholder: t('patient.patientCheckoutPage.quotationForm.dayPlaceholder'),
  },
  {
    name: 'dobMonth',
    options: monthOptions,
    placeholder: t('patient.patientCheckoutPage.quotationForm.monthPlaceholder'),
  },
  {
    name: 'dobYear',
    options: yearOptions,
    placeholder: t('patient.patientCheckoutPage.quotationForm.yearPlaceholder'),
  },
];

export interface DateOfBirthFieldProps {
  onChange?: (date: string) => void;
  labelText: string;
  errorText?: string;
  hasError?: boolean;
}

// Tests against 'yyyy-MM-dd'
const dateFormatRegex = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/;

export const DateOfBirthField = ({ errorText, onChange, labelText, hasError }: DateOfBirthFieldProps) => {
  const screenIsMobile = useMediaQuery('max-width: 767px');
  const [date, setDate] = useState<Date>({ dobDay: '', dobMonth: '', dobYear: '' });
  const [invalidDateError, setInvalidDateError] = useState(false);

  const setDateOfBirth = (value: string, name: keyof Date) => {
    setDate((existingDate) => ({ ...existingDate, [name]: value }));
    const newDate = { ...date, [name]: value };
    const { dobDay, dobMonth, dobYear } = newDate;

    const dateString = `${dobYear}-${dobMonth}-${dobDay}`;
    onChange?.(dateString);
    if (dateFormatRegex.test(dateString)) {
      const date = DateTime.fromFormat(dateString, 'yyyy-MM-dd');
      setInvalidDateError(!date.isValid);
    }
  };

  return (
    <div>
      <div className="mb-2">
        <Label size="small" helperText={labelText} />
      </div>
      <>
        <div className="grid grid-cols-3 gap-2">
          {dateDropdowns.map(({ name, options, placeholder }) => (
            <div key={name}>
              <Selector
                options={options}
                size="medium"
                onChange={({ value }) => setDateOfBirth(value, name)}
                placeholder={placeholder}
                defaultSelectedIndex={options.findIndex((option) => option.value === date[name])}
                testId={name}
                hasError={hasError || invalidDateError}
                dropdownAlignment={name === 'dobYear' && screenIsMobile ? 'right' : 'left'}
              />
            </div>
          ))}
        </div>
        {(errorText || invalidDateError) && (
          <div data-testid="date-field-error-text" className="mt-2">
            <ErrorText
              icon="info"
              text={invalidDateError ? t('patient.patientCheckoutPage.quotationForm.invalidDateError') : errorText!}
            />
          </div>
        )}
      </>
    </div>
  );
};
