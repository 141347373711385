import { t } from 'i18next';
import { isValidPhoneNumber } from 'libphonenumber-js';
import * as z from 'zod';

type ValidationMessages = {
  required: string;
  invalid: string;
};

export const phoneNumberValidation = (optional: boolean) =>
  z
    .string()
    .refine((value) => (optional && value === '') || /^[\d\s]+$/.test(value), {
      message: t('common.phoneValidation'),
    })
    .transform((value) => value.replace(/\s+/g, '')) // Remove all spaces
    .refine((value) => (optional && value === '') || value.length === 11, {
      message: t('common.phoneValidation'),
    })
    .refine((value) => (optional && value === '') || isValidPhoneNumber(value, 'GB'), {
      message: t('common.phoneValidation'),
    });

export const validations = {
  email: (
    { required, invalid }: ValidationMessages = {
      required: 'Email address is required',
      invalid: 'Enter in the format: name@example.com',
    },
  ) => z.string().min(1, { message: required }).email({ message: invalid }),
  onlyLetters: (message: string) =>
    z
      .string()
      .min(1, { message })
      .regex(new RegExp(/^[a-zA-Z]+[-'s]?[a-zA-Z ]+$/), { message }),
  password: z
    .string()
    .regex(new RegExp('.*[A-Z].*'), 'Must contain 1 uppercase letter')
    .regex(new RegExp('.*[a-z].*'), 'Must contain 1 lowercase letter')
    .regex(new RegExp('.*\\d.*'), 'Must contain 1 number')
    .regex(new RegExp('.*[`~<>?,./!@#$%^&*()\\-_+="\'|{}\\[\\];:\\\\].*'), 'Must contain 1 special character')
    .min(8, 'Must be at least 8 characters'),
  amount: (
    { required, invalid }: ValidationMessages = {
      required: 'Value is required',
      invalid: 'Value is not valid',
    },
  ) =>
    z
      .string()
      .regex(new RegExp(/(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/), { message: invalid })
      .min(1, { message: required }),
  postcode: z
    .string({
      message: t('plans.patientPayerInfo.billingInfo.error.postcode'),
    })
    .min(1, {
      message: t('plans.patientPayerInfo.billingInfo.error.postcode'),
    })
    .regex(
      new RegExp(
        /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/,
      ),
      t('plans.patientPayerInfo.billingInfo.error.postcode'),
    ),
  sortcode: z.string().regex(new RegExp(/^[0-9][0-9]-[0-9][0-9]-[0-9][0-9]$/), 'Enter in the format: 00-00-00'),
};
