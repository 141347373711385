import { RadioProps } from './Radio';

export const baseClasses =
  'cursor-pointer border border-borderNeutralDefault rounded-full flex items-center text-center relative';
export const baseDotClasses = 'mx-auto bg-white items-center flex justify-center rounded-full';

export const getSizeClasses = (size?: RadioProps['radioSize']) => {
  switch (size) {
    case 'small':
      return `h-4 w-4`;
    case 'medium':
      return `h-6 w-6`;
    case 'large':
      return `h-7 w-7`;
    default:
      return `h-6 w-6`;
  }
};

export const getDotSizeClasses = (size?: RadioProps['radioSize']) => {
  switch (size) {
    case 'small':
      return 'h-2 w-2';
    case 'medium':
      return 'h-2.5 w-2.5';
    case 'large':
      return 'h-3 w-3';
    default:
      return 'h-2.5 w-2.5';
  }
};

export const getDisabledDotClasses = (disabled?: RadioProps['disabled']) => {
  switch (disabled) {
    case true:
      return '!bg-foregroundLightPrimaryDisabled';
    case false:
      return 'bg-white';
    default:
      return 'bg-white';
  }
};

export const getStateClasses = (checked: boolean, disabled: boolean) => {
  if (disabled) {
    switch (checked) {
      case true:
        return '!bg-foregroundNeutralDisabled !border-foregroundNeutralDisabled !hover:bg-foregroundNeutralDisabled !cursor-not-allowed';
      case false:
        return '!bg-foregroundLightPrimaryDisabled !border-borderNeutralDefaultDisabled !hover:bg-foregroundLightPrimaryDisabled !cursor-not-allowed';
      default:
        return '!bg-foregroundLightPrimaryDisabled !border-borderNeutralDefaultDisabled !hover:bg-foregroundLightPrimaryDisabled !cursor-not-allowed';
    }
  } else {
    switch (checked) {
      case true:
        return '!bg-backgroundBrandDefault !border-backgroundBrandDefault';
      case false:
        return 'border-borderNeutralDefault bg-white';
    }
  }
};

export const getHoverClasses = (checked?: boolean) => {
  switch (checked) {
    case true:
      return '!hover:bg-backgroundBrandDefaultHover hover:border-backgroundBrandDefaultHover';
    case false:
      return 'hover:border-foregroundNeutralTertiaryHover';
    default:
      return 'hover:border-foregroundNeutralTertiaryHover';
  }
};
