import { Size } from '@library/types';
import { getPaddedSize } from '@library/utils';
import clsx from 'clsx';

import { Label } from '../Label';

export interface TabOption {
  label: string;
  value: string;
}

export interface ToggleTabProps {
  options: TabOption[];
  selectedIndex: number;
  setSelected: (index: number) => void;
  testId?: string;
  size?: Size;
  labelText?: string;
}

export const ToggleTab = ({
  options,
  testId,
  size = 'small',
  labelText,
  setSelected,
  selectedIndex,
}: ToggleTabProps) => (
  <label data-testid={`label-${testId}`} className="select-none flex flex-col gap-[10px]">
    {labelText && (
      <Label
        size={size}
        labelText={labelText}
        labelStrong={false}
        appearance="secondary"
        className={clsx('order-first', 'cursor-pointer')}
      />
    )}
    <div className="flex border border-borderNeutralDefault rounded-lg overflow-hidden" data-testid={testId}>
      {options.map((option, index) => {
        return (
          <div
            className={clsx(
              index !== options.length - 1 && 'border-r',
              index === selectedIndex && 'bg-backgroundBlueSoft',
              getPaddedSize(size),
              'border-borderNeutralDefault items-center w-full justify-center flex cursor-pointer hover:bg-backgroundBlueSoft h-10',
            )}
            key={`${option.value}-${index}`}
            data-testid={`option-${option.value}`}
            onClick={() => {
              setSelected(index);
            }}
          >
            <div className="justify-start items-center flex">
              <div className="text-foregroundNeutralPrimary font-normal text-sm tracking-[0.02rem]">{option.label}</div>
            </div>
          </div>
        );
      })}
    </div>
  </label>
);
