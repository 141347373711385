import { NavLink } from 'react-router-dom';
import { Avatar } from '@library/components/atoms/Avatar';
import { TableCell } from '@library/components/atoms/TableCell';
import { Label } from '@library/components/molecules';
import { CellContext } from '@tanstack/react-table';
import { ICellRendererParams } from 'ag-grid-community';
import clsx from 'clsx';

import { PatientOverview } from '@shared/data/types';
import { useTreatmentGuideContext } from '@shared/hooks';

import { elipsis, TextRenderer } from '../TextRenderer/TextRenderer';

export const PatientRenderer = ({ value, data }: ICellRendererParams<PatientOverview>) => {
  return (
    <NavLink
      data-testid="patient-link"
      className={clsx(elipsis)}
      to={`/practice/dashboard/patients/${data?.treatmentGuideId}`}
    >
      <TextRenderer variant="l14" text={value} className="text-primary-main" />
    </NavLink>
  );
};

export const PatientRendererNewDS = (props: CellContext<PatientOverview, string>) => {
  const value = props.getValue();
  const treatmentGuideContext = useTreatmentGuideContext();
  return (
    <TableCell>
      <div
        data-testid="patient-link"
        className={clsx('flex gap-2 items-center !cursor-pointer pointer-events-auto', elipsis)}
        onClick={() => {
          if (props?.row.original.canEdit) {
            treatmentGuideContext?.setTreatmentGuideModalData({
              treatmentGuideId: props?.row.original.treatmentGuideId,
              isOpen: true,
            });
          }
        }}
      >
        <Avatar text={value[0]} size={32} />
        <Label
          truncate
          appearance="secondary"
          size="small"
          labelText={value}
          labelStrong={false}
          className="pointer-events-none"
        />
      </div>
    </TableCell>
  );
};
