import { KeyboardEvent, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { Button } from '@library/components/molecules';

import { Icon, IconNameType } from '@shared/components/Icon';
import { Typography } from '@shared/components/Typography';

export interface MenuItem {
  icon: IconNameType;
  testId: string;
  label: string;
  onClick: () => void;
  metaData?: ReactNode;
}

export interface ActionMenuProps {
  menuItems: MenuItem[];
  useBoundingRect?: boolean;
}

export const ActionMenu = ({ menuItems }: ActionMenuProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const elementRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = useCallback((event: MouseEvent) => {
    if (elementRef.current && !elementRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  }, []);

  const handleKeyDown = (event: KeyboardEvent<HTMLButtonElement>) => {
    if (isOpen && event.key === 'Escape') {
      setIsOpen(false);
      if (document.activeElement instanceof HTMLElement) {
        document.activeElement.blur();
      }
    }
  };

  useEffect(() => {
    window.document.addEventListener('click', handleClickOutside);
    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="relative" ref={elementRef}>
      <Button
        testId="action-menu-icon"
        onClick={() => {
          setIsOpen((open) => !open);
        }}
        leftIcon="more-dots"
        size="small"
        appearance="ghost"
        mode="accent"
        onKeyDown={handleKeyDown}
        iconColor="text-foregroundNeutralQuaternary"
      />
      {isOpen && (
        <div className="absolute right-0 z-modalPanel" data-testid="action-menu">
          <div className="bg-white p-4 w-[16rem] rounded-md border border-grey-100 text-left">
            <div className="grid grid-cols-1">
              {menuItems &&
                menuItems.map(({ icon, testId, label, onClick, metaData }) => (
                  <button
                    data-testid={`action-menu-${testId}`}
                    className="text-left p-2 flex gap-2 items-center hover:bg-primary-light"
                    onClick={() => {
                      onClick();
                      setIsOpen(false);
                    }}
                    key={label}
                  >
                    <Icon name={icon} size={24} className="stroke-primary-dark stroke-2" />
                    <Typography variant="b16">{label}</Typography>
                    {metaData}
                  </button>
                ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
