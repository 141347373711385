import { create } from 'zustand'
import { persist } from 'zustand/middleware'

import { practiceFetch } from '@shared/utils/fetch'
import { newApiBaseUrl, newApiUrls } from '@shared/utils/urls'

export interface ProductTourStepProps {
  targetId?: string
  title: string
  supportText: string
  position: 'top' | 'bottom' | 'left' | 'right'
  alignment?: 'top' | 'left' | 'right' | 'center'
  margin?: {
    top: number
    left: number
  }
  nextButton?: string
  altButton?: string
  altFunction?: () => void
}

export type ProductTourState = {
  currentStep: number
  tourIsActive: boolean
  hasCompleted: boolean
  steps: ProductTourStepProps[]
  isLoading: boolean
  openSelector: boolean
  tourLocation: string | undefined
  setOpenSelector: (open: boolean) => void
  setTourLocation: (location: string) => void
  initializeProductTour: () => void
  setSteps: (steps: ProductTourStepProps[]) => void
  setCurrentStep: (step: number) => void
  completeTour: () => void
  nextStep: () => void
}

export const useProductTourStore = create<ProductTourState>()(
  persist(
    (set, get) => ({
      currentStep: 0,
      tourIsActive: false,
      hasCompleted: false,
      steps: [],
      isLoading: false,
      userId: '',
      practiceId: '',
      openSelector: false,
      tourLocation: undefined,
      setTourLocation: (location: string) => set({ tourLocation: location }),
      setOpenSelector: (open: boolean) => set({ openSelector: open }),
      initializeProductTour: () => {
        set({
          hasCompleted: false,
          currentStep: 0,
          tourIsActive: true,
        })
      },
      setSteps: (steps) => set({ steps }),
      setCurrentStep: (step) => set({ currentStep: step }),
      completeTour: async () => {
        set({ isLoading: true })
        await practiceFetch({
          url: `${newApiBaseUrl}${newApiUrls.auth}${newApiUrls.user}`,
          method: 'PATCH',
          data: { hasOnboarded: true },
        })
        set({ hasCompleted: true, tourIsActive: false, isLoading: false })
      },
      nextStep: () => {
        const { currentStep, steps } = get()
        if (currentStep < steps.length - 1) {
          set({ currentStep: currentStep + 1 })
        } else {
          get().completeTour()
        }
      },
    }),
    {
      name: 'product-tour-storage',
      partialize: (state) => ({
        hasCompleted: state.hasCompleted,
      }),
    },
  ),
)
