import { memo } from 'react';
import { DataTable } from '@library/components/molecules/DataTable/DataTable';
import { Empty } from '@library/components/organisms';
import { Table } from '@tanstack/react-table';
import { t } from 'i18next';

import { PayoutOverview } from '@shared/data/types';

import { useTable } from './useTable';

export interface PayoutsTableProps {
  payouts: PayoutOverview[];
  payoutsLoading: boolean;
  refetchPayoutOverview: () => void;
}

export const PayoutsTable = memo(({ payouts, payoutsLoading }: PayoutsTableProps) => {
  const { setPageIndex, table } = useTable({
    payoutsOverview: payouts,
  });

  const displayedRowCount = table.getFilteredRowModel().rows.length;

  const noPayoutsExist = !payoutsLoading && !displayedRowCount;

  return (
    <div data-testid="payout-table-wrapper" className="w-full">
      <DataTable
        table={table as Table<unknown>}
        displayedRowCount={displayedRowCount}
        setPageIndex={setPageIndex}
        isEmpty={noPayoutsExist && !payoutsLoading}
        loading={payoutsLoading}
        emptyState={
          <>
            <style>{`.guide-table {display: none}`}</style>
            <Empty
              background
              description={t('practice.plansDashboard.payouts.emptyState')}
              illustration="confused1"
              size="large"
              title={t('practice.plansDashboard.payouts.emptyTitle')}
            />
          </>
        }
      />
    </div>
  );
});

PayoutsTable.displayName = 'SubscriptionTable';
