import { useCallback, useEffect, useMemo, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { Divider } from '@library/components/atoms';
import PageContentWrapper from '@library/components/atoms/PageContentWrapper';
import { ContentBlockWrapper } from '@library/components/atoms/PageContentWrapper/PageContentWrapper';
import { CheckoutFinanceOptions } from '@library/components/organisms/CheckoutFinanceOptions/CheckoutFinanceOptions';
import CheckoutPatientDecisionBlock from '@library/components/organisms/CheckoutPatientDecisionBlock';
import CheckoutPatientDecisionInfo from '@library/components/organisms/CheckoutPatientDecisionInfo';
import { t } from 'i18next';

import { TreatmentOverviewCard } from '@patient/components/TreatmentOverviewCard/TreatmentOverviewCard';
import { useGetCheckoutAccordionItems } from '@patient/pages/CheckoutPage/useGetAccordionItems';
import { TreatmentNames } from '@practice/pages/PatientsPages/PatientsDashboard/PatientTable/types';
import CheckoutAccordian from '@shared/components/CheckoutAccordian';
import { Spinner } from '@shared/components/Spinner';
import { Typography } from '@shared/components/Typography';
import {
  useGetPractice,
  useGetTreatmentDefinition,
  useGetTreatmentGuide,
  usePatchTreatmentGuides,
} from '@shared/data/practice/hooks';
import {
  Accessor,
  IPaymentMethod,
  ITreatmentDefinition,
  ITreatmentGuideDecision,
  NotGoingAheadReason,
  PatientDecision,
  PricingType,
} from '@shared/data/types';
import { useDividebuyLoanApplicationStore } from '@shared/stores/dividebuyLoanApplicationStore';
import { PatientMixpanel } from '@shared/utils/patientMixpanel';

export const PatientCheckout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { interestRate, setData, term } = useDividebuyLoanApplicationStore();

  const [decisionBlockVisible, setDecisionBlockVisible] = useState(false);

  const [isPatientNotSure, setIsPatientNotSure] = useState(false);
  const [notSureReason, setNotSureReason] = useState<NotGoingAheadReason | undefined>();

  const treatmentGuideId = localStorage.getItem('treatmentGuideId') || '';
  const practiceId = treatmentGuideId.split(':')[0];

  const {
    data: treatmentGuide,
    isLoading: treatmentGuideIsLoading,
    refetch: refetchTreatmentGuide,
  } = useGetTreatmentGuide(treatmentGuideId, Accessor.PATIENT);
  const { data: practice, isLoading: practiceIsLoading } = useGetPractice(practiceId, Accessor.PATIENT);

  const treatmentGuideData = treatmentGuide?.data;

  const treatments = treatmentGuideData?.treatments;

  const accordionItems = useGetCheckoutAccordionItems({
    treatmentGuideData,
    // Do not display finance options in accordion as per the new designs
    financeEnabled: false,
    practiceName: practice?.data.name,
  });

  const { data: treatmentDefinitions } = useGetTreatmentDefinition(undefined, Accessor.PATIENT, true);

  const treatmentNames = useMemo(
    () =>
      treatmentGuideData?.treatments
        ? treatmentGuideData.treatments.map(
            (treatment) =>
              ((treatmentDefinitions?.data as ITreatmentDefinition[]) || []).find(
                ({ id }) => id === treatment.treatmentDefinition,
              )?.name || '',
          )
        : [],
    [treatmentGuideData, treatmentDefinitions],
  );

  const patchTreatmentGuide = usePatchTreatmentGuides(Accessor.PATIENT, {
    onSuccess: () => {
      refetchTreatmentGuide();
      setDecisionBlockVisible(false);
    },
  });

  const trackDecision = useCallback(
    (eventProperties: Record<string, string>) => {
      PatientMixpanel.track('Patient made a decision on treatment', {
        'Practice name': practice?.data.name,
        'Treatment name':
          treatments ||
          []
            .map(({ treatmentDefinition }) => TreatmentNames[treatmentDefinition as keyof typeof TreatmentNames])
            .join(', '),
        'Treatment price': parseInt(treatmentGuide?.data.price || '0'),
        ...eventProperties,
      });
    },
    [practice?.data.name, treatments, treatmentGuide?.data.price],
  );

  const onClickImInterested = () => {
    patchTreatmentGuide.mutate(
      { id: treatmentGuideId, decision: ITreatmentGuideDecision.INTERESTED, reason: null },
      {
        onSuccess: () => {
          trackDecision({
            decision: PatientDecision.IM_INTERESTED,
          });
        },
      },
    );
  };

  const onClickPayInPracice = () => {
    patchTreatmentGuide.mutate(
      {
        id: treatmentGuideId,
        decision: ITreatmentGuideDecision.ACCEPTED,
        reason: null,
      },
      {
        onSuccess: () => {
          trackDecision({
            'Decision': PatientDecision.ID_LIKE_TO_GOAHEAD,
            'Payment method': 'Pay in practice',
          });
        },
      },
    );
  };

  const onClickConfirmNotSureReason = () => {
    setIsPatientNotSure(false);
    patchTreatmentGuide.mutate(
      {
        id: treatmentGuideId,
        decision: ITreatmentGuideDecision.DECLINED,
        reason: notSureReason,
      },
      {
        onSuccess: () => {
          /** clear the not sure reason */
          setNotSureReason(undefined);
          trackDecision({
            'Decision': PatientDecision.IM_NOT_SURE,
            'Payment method': 'Pay in practice',
          });
        },
      },
    );
  };

  useEffect(() => {
    if (treatmentGuideData && treatmentGuideId && practice?.data.name) {
      if (location?.state?.signedIn) {
        PatientMixpanel.identify(treatmentGuideId, practice?.data.name);

        if (treatmentGuideData?.displayIntroduction && !location.state?.hasSeenIntroductionPage) {
          navigate('/patient/login/introduction');
        }

        if (treatmentGuideData.paymentMethod === IPaymentMethod.FINANCE) {
          navigate('/patient/dashboard/finance-confirmation');
        }
      }
    }
  }, [treatmentGuideData, practice, location.state, navigate, treatmentGuideId]);

  if (treatmentGuideIsLoading || practiceIsLoading) return <Spinner />;

  if (!treatmentGuideData) {
    return null;
  }

  if (practice && treatmentGuideData.archived) {
    return <Navigate to="/patient/archived-guide" replace />;
  }

  if (!practice?.data) {
    return <p>No practice</p>;
  }

  const displayFinanceConfirmation = treatmentGuideData.paymentMethod === IPaymentMethod.FINANCE;
  const displayPayInPracticeConfirmation = treatmentGuideData.paymentMethod === IPaymentMethod.PAY_IN_PRACTICE;
  const displayInterestedConfirmation =
    treatmentGuideData.pricingType !== PricingType.FINAL &&
    treatmentGuideData.decision === ITreatmentGuideDecision.INTERESTED;
  const patientIsUnsure = treatmentGuideData.decision === ITreatmentGuideDecision.DECLINED;

  const paymentConfirmed = displayFinanceConfirmation || displayPayInPracticeConfirmation;

  const existingDecision =
    displayFinanceConfirmation || displayPayInPracticeConfirmation || displayInterestedConfirmation || patientIsUnsure;

  return (
    <>
      <PageContentWrapper className="!mb-0">
        <Typography variant="h3" className="mb-4">
          {t('patient.patientCheckoutPage.heading', { name: treatmentGuideData.firstName })}
        </Typography>
        <Typography variant="h6" className="mb-4">
          {`${
            (treatments?.length || 0) > 1
              ? t('patient.patientCheckoutPage.subHeadingMultiTreatments')
              : t('patient.patientCheckoutPage.subHeadingSingleTreatment')
          }`}
        </Typography>
        <div className="flex flex-col gap-4 mb-2">
          {treatments &&
            treatments.length > 0 &&
            treatments.map((treatment) => (
              <TreatmentOverviewCard key={treatment.treatmentDefinition} treatment={treatment} />
            ))}
        </div>
        <CheckoutAccordian icons={{ open: 'minus', close: 'plus' }} items={accordionItems} />
        {!paymentConfirmed && <Divider className="mb-4" margin="medium" />}
        {!paymentConfirmed && treatmentGuideData?.price && practice.data.offersFinance && (
          <CheckoutFinanceOptions
            displayPricing={treatmentGuideData.displayPricing}
            price={parseInt(treatmentGuideData.price)}
            deposit={parseInt(treatmentGuideData.deposit || '0')}
            practiceName={practice.data.name}
            treatments={treatmentNames}
            selectedOption={interestRate !== null && term ? `${interestRate}-${term}` : ''}
            setSelectedTerm={setData}
            pricingType={treatmentGuideData.pricingType}
          />
        )}
      </PageContentWrapper>
      {treatmentGuideData.decision && !decisionBlockVisible && !patchTreatmentGuide.isLoading && existingDecision ? (
        <ContentBlockWrapper>
          <CheckoutPatientDecisionInfo
            practicePhone={practice.data.phone}
            practiceEmail={practice.data.email}
            practiceName={practice.data.name}
            displayFinanceConfirmation={displayFinanceConfirmation}
            displayPayInPracticeConfirmation={displayPayInPracticeConfirmation}
            displayInterestedConfirmation={displayInterestedConfirmation}
            patientIsUnsure={patientIsUnsure}
            setDecisionBlockVisible={setDecisionBlockVisible}
          />
        </ContentBlockWrapper>
      ) : (
        <PageContentWrapper className="!mt-0">
          {!patchTreatmentGuide.isLoading && (
            <>
              <Divider className="mb-4" margin="medium" />
              <CheckoutPatientDecisionBlock
                financeOnly={practice.data.financeOnly}
                isPatientNotSure={isPatientNotSure}
                setIsPatientNotSure={setIsPatientNotSure}
                notSureReason={notSureReason}
                setNotSureReason={setNotSureReason}
                onClickConfirmNotSureReason={onClickConfirmNotSureReason}
                onClickPayInPracice={onClickPayInPracice}
                onClickImInterested={onClickImInterested}
                noPriceOrEstimated={Boolean(
                  treatmentGuideData.pricingType === PricingType.ESTIMATED || !treatmentGuideData.price,
                )}
              />
            </>
          )}
        </PageContentWrapper>
      )}
    </>
  );
};
