import { CheckoutInterestedModule } from '@patient/components/CheckoutInterestedModule';
import { CheckoutNotSureConfirmation } from '@patient/components/CheckoutNotSureConfirmation';
import { CheckoutPatientDecisionContainer } from '@patient/components/CheckoutPatientDecisionBlock';
import { CheckoutPaymentConfirmed } from '@patient/components/CheckoutPaymentConfirmed';

interface CheckoutPatientDecisionInfo {
  practicePhone: string;
  practiceEmail: string;
  practiceName: string;
  displayPayInPracticeConfirmation: boolean;
  displayInterestedConfirmation: boolean;
  displayFinanceConfirmation: boolean;
  patientIsUnsure: boolean;
  setDecisionBlockVisible: (value: boolean) => void;
}

/* Allows the patients to make a decision */
export const CheckoutPatientDecisionInfo = ({
  practiceEmail,
  practicePhone,
  practiceName,
  displayInterestedConfirmation,
  displayPayInPracticeConfirmation,
  displayFinanceConfirmation,
  patientIsUnsure,
  setDecisionBlockVisible,
}: CheckoutPatientDecisionInfo) => {
  if (displayInterestedConfirmation) {
    return (
      <CheckoutPatientDecisionContainer maxWidth={false}>
        <CheckoutInterestedModule practiceNumber={practicePhone} email={practiceEmail} />
      </CheckoutPatientDecisionContainer>
    );
  }
  if (displayFinanceConfirmation || displayPayInPracticeConfirmation) {
    return (
      <CheckoutPatientDecisionContainer maxWidth={false}>
        <CheckoutPaymentConfirmed
          practiceName={practiceName}
          practiceNumber={practicePhone}
          email={practiceEmail}
          hasSeenConfirmation
          payInPractice={displayPayInPracticeConfirmation}
        />
      </CheckoutPatientDecisionContainer>
    );
  }
  if (patientIsUnsure) {
    return (
      <CheckoutPatientDecisionContainer maxWidth={false}>
        <CheckoutNotSureConfirmation
          patientHasDeclined
          practiceName={practiceName}
          practiceNumber={practicePhone}
          email={practiceEmail}
          setDisplayDecisionOptions={setDecisionBlockVisible}
        />
      </CheckoutPatientDecisionContainer>
    );
  }

  return null;
};
