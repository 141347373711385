import { ChangeEvent, useEffect } from 'react';
import { Controller, FieldError, useForm } from 'react-hook-form';
import { Trans } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { Divider, ErrorText, Heading, Paragraph } from '@library/components/atoms';
import { Button, Input, Option, Selector } from '@library/components/molecules';
import { DateOfBirthField } from '@library/components/molecules/DateOfBirthField/DateOfBirthField';
import { titleOptions } from '@library/utils';
import { t } from 'i18next';
import { DateTime } from 'luxon';

import { isPriceValid } from '@practice/components/PriceEditor/utils';
import { PostQuotationBody } from '@shared/data/types';

import { PostcodeInput } from '../PostcodeInput';
import { usePostcodeLookUp } from '../PostcodeInput/usePostcodeLookUp';

import { QuotationApplicantionSchema, quotationApplicantionSchema as quotationSchema } from './validations';

interface QuotationApiFormProps {
  deposit?: number;
  displayPricing?: boolean;
  practiceName?: string;
  term: number;
  onSubmitForm: (body: PostQuotationBody) => void;
  treatmentGuideId: string;
}

export const QuotationApiForm = ({ term, treatmentGuideId, onSubmitForm }: QuotationApiFormProps) => {
  const quotationFormProps = useForm<QuotationApplicantionSchema>({
    resolver: zodResolver(quotationSchema),
    defaultValues: { addresses: [{}] },
  });

  const watchResidentialStatus = quotationFormProps.watch('residentialStatus');
  const _watchAddresses = quotationFormProps.watch('addresses');

  const hideRentField = ['Yes, outright owner', 'No, other', undefined].includes(watchResidentialStatus);

  useEffect(() => {
    if (hideRentField) {
      quotationFormProps.resetField('rent');
    }
  }, [hideRentField, quotationFormProps]);

  const submitForm = async () => {
    const quotationApplicationValid = await quotationFormProps.trigger();
    if (quotationApplicationValid && term) {
      const {
        instalmentDate,
        // the below fields should not be sent
        dobDay,
        dobMonth,
        dobYear,
        addresses,
        ...customer
      } = quotationFormProps.getValues();
      const body: PostQuotationBody = {
        treatmentGuideId,
        selectedInstalmentTerm: term,
        instalmentDate,
        customer: {
          ...customer,
          annualSalary: parseInt(customer.annualSalary),
          rent: customer.rent ? parseInt(customer.rent) : undefined,
          address: {
            addressLine1: addresses[0].addressline1,
            addressLine2: addresses[0].addressline2,
            addressLine3: addresses[0].addressline3,
            town: addresses[0].posttown,
            postcode: addresses[0].postcode,
            moveInDate: addresses[0].moveInDate,
          },
        },
      };

      if (addresses && addresses?.length > 1) {
        body.customer.previousAddresses = addresses
          .slice(1)
          .map(({ addressline1, addressline2, addressline3, posttown, postcode }) => ({
            addressLine1: addressline1,
            addressLine2: addressline2,
            addressLine3: addressline3,
            town: posttown,
            postcode: postcode,
            moveInDate: addresses![0].moveInDate,
          }));
      }

      onSubmitForm(body);
    }
  };

  const [addressOptions, searchByPostcode, postcodeError] = usePostcodeLookUp();

  useEffect(() => {
    const validMoveInDateIndex = _watchAddresses.findIndex((address) => {
      if (address.moveInDate) {
        const now = DateTime.now();
        const twelveMonthsAgo = now.minus({ months: 12 });
        const date = DateTime.fromFormat(address.moveInDate, 'yyyy-MM-dd');
        return date.isValid && date <= twelveMonthsAgo;
      }
      return false;
    });
    if (validMoveInDateIndex > -1) {
      const newSlice = _watchAddresses.slice(0, validMoveInDateIndex + 1);
      if (newSlice.length !== _watchAddresses.length) {
        quotationFormProps.setValue('addresses', newSlice);
      }
    } else {
      const lastAddress = _watchAddresses[_watchAddresses.length - 1];
      if (lastAddress.moveInDate) {
        const newS = [
          ..._watchAddresses.slice(0),
          {
            moveInDate: '',
            posttown: '',
            postcode: '',
            addressline1: '',
          },
        ];
        quotationFormProps.setValue(`addresses`, newS);
      }
    }
  }, [_watchAddresses, quotationFormProps]);

  return (
    <form onSubmit={(event) => event.preventDefault()} className="flex flex-col gap-2 mt-8 mb-16">
      <div className="flex flex-col gap-4">
        <Heading variant="h6">{t('patient.patientCheckoutPage.quotationForm.aboutYouSection.title')}</Heading>
        <Paragraph size="medium">
          {t('patient.patientCheckoutPage.quotationForm.aboutYouSection.description')}
        </Paragraph>
        <Controller
          name="title"
          control={quotationFormProps.control}
          render={({ field, fieldState }) => (
            <Selector
              options={titleOptions}
              size="medium"
              labelText="Title"
              testId="title"
              placeholder={t('patient.patientCheckoutPage.quotationForm.selectorPlaceholder')}
              onChange={({ value }) => field.onChange(value)}
              validationError={fieldState.error?.message}
              defaultSelectedIndex={titleOptions.findIndex((option) => option.value === field.value)}
            />
          )}
        />
        <Controller
          name="firstName"
          control={quotationFormProps.control}
          render={({ field, fieldState }) => (
            <Input
              testId="firstName"
              inputSize="medium"
              type="text"
              id="firstName"
              labelText="First name"
              labelSize="small"
              placeholder={t('patient.patientCheckoutPage.quotationForm.firstNamePlaceholder')}
              preventNumericValues
              errorText={fieldState.error?.message}
              {...field}
            />
          )}
        />
        <Controller
          name="lastName"
          control={quotationFormProps.control}
          render={({ field, fieldState }) => (
            <Input
              testId="lastName"
              inputSize="medium"
              type="text"
              id="lastName"
              labelText="Last name"
              labelSize="small"
              placeholder={t('patient.patientCheckoutPage.quotationForm.lastNamePlaceholder')}
              preventNumericValues
              errorText={fieldState.error?.message}
              {...field}
            />
          )}
        />
        <Controller
          name="email"
          control={quotationFormProps.control}
          render={({ field, fieldState }) => (
            <Input
              testId="email"
              inputSize="medium"
              type="text"
              id="email"
              labelText="Email"
              labelSize="small"
              placeholder={t('patient.patientCheckoutPage.quotationForm.emailPlaceholder')}
              errorText={fieldState.error?.message}
              {...field}
            />
          )}
        />
        <Controller
          key="dateOfBirth"
          name="dateOfBirth"
          control={quotationFormProps.control}
          render={({ fieldState }) => (
            <DateOfBirthField
              labelText="Date of birth"
              onChange={(value) => {
                quotationFormProps.setValue('dateOfBirth', value);
              }}
              errorText={fieldState?.error?.message}
            />
          )}
        />
        <Controller
          name="mobileNumber"
          control={quotationFormProps.control}
          render={({ field, fieldState }) => (
            <Input
              testId="mobileNumber"
              {...field}
              inputSize="large"
              labelText="Phone"
              labelSize="small"
              labelBadgeAppearance="blue"
              type="tel"
              id="mobileNumber"
              onlyNumericValues
              placeholder={t('patient.patientCheckoutPage.quotationForm.mobileNumberPlaceholder')}
              errorText={fieldState.error?.message || undefined}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                field.onChange(e);
                quotationFormProps?.clearErrors('mobileNumber');
              }}
              onBlur={() => {
                quotationFormProps?.trigger('mobileNumber');
              }}
            />
          )}
        />
      </div>
      <div className="flex flex-col gap-4">
        <Divider className="mt-6" margin="small" />
        <Heading variant="h6">{t('patient.patientCheckoutPage.quotationForm.addressSection.title')}</Heading>
        <Paragraph size="medium">{t('patient.patientCheckoutPage.quotationForm.addressSection.description')}</Paragraph>
        <Controller
          name="addresses"
          control={quotationFormProps.control}
          render={({ field, fieldState }) => {
            return (
              <div className="flex flex-col gap-4">
                {field.value.map((_item, index) => {
                  const address = _item;
                  const addressError = (fieldState.error as unknown as { moveInDate: FieldError }[])?.[index];
                  const moveInDateErrorText = addressError?.moveInDate?.message;
                  const displayMoveInDate = Object.keys(_watchAddresses[index]).length > 0;
                  let lessThanTwelveMonthsAgo = false;
                  if (address.moveInDate) {
                    const date = DateTime.fromFormat(address.moveInDate, 'yyyy-MM-dd');
                    const now = DateTime.now();
                    lessThanTwelveMonthsAgo = date > now.minus({ months: 12 });
                  }
                  return (
                    <div key={index}>
                      {index >= 1 && (
                        <Heading className="mt-6 mb-6" variant="h6">
                          {t('patient.patientCheckoutPage.quotationForm.previousAddressTitle')}
                        </Heading>
                      )}
                      <div className="flex flex-col gap-4">
                        <PostcodeInput
                          key={index}
                          searchByPostcode={searchByPostcode}
                          addressOptions={addressOptions}
                          fieldNamePrefix={`addresses.${index}.`}
                          formProps={quotationFormProps}
                          postcodeError={postcodeError}
                          placeholder={t('patient.patientCheckoutPage.quotationForm.addressPlaceholder')}
                          addressLine3
                          disableForm
                          postcodeFinderError={
                            (fieldState.error as unknown as { postcode: { message: string } }[])?.[index]?.postcode
                              ?.message
                          }
                        />
                        {displayMoveInDate && (
                          <DateOfBirthField
                            labelText={t('patient.patientCheckoutPage.quotationForm.moveInDateLabel')}
                            onChange={(value) => {
                              const date = DateTime.fromFormat(value, 'yyyy-MM-dd');
                              if (date.isValid) {
                                quotationFormProps.setValue(`addresses`, [
                                  ..._watchAddresses.slice(0, index),
                                  { ..._watchAddresses[index], moveInDate: value },
                                  ..._watchAddresses.slice(index + 1),
                                ]);
                              }
                            }}
                            hasError={Boolean(moveInDateErrorText)}
                            errorText={moveInDateErrorText}
                          />
                        )}
                        {address.postcode && address.moveInDate && lessThanTwelveMonthsAgo && (
                          <div className="flex felx-col gap-4 p-5 bg-backgroundNeutralSoft rounded-2xl">
                            <div>🏠</div>
                            <Paragraph size="medium">
                              {t('patient.patientCheckoutPage.quotationForm.previousAddressInfo')}
                            </Paragraph>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
                {fieldState.error?.root?.message && (
                  <div>
                    <ErrorText icon="info" text={fieldState.error.root.message} />
                  </div>
                )}
              </div>
            );
          }}
        />
      </div>
      <div className="flex flex-col gap-4">
        <Divider margin="small" />
        <Heading variant="h6">{t('patient.patientCheckoutPage.quotationForm.incomeTitle')}</Heading>
        <Controller
          name="incomeType"
          control={quotationFormProps.control}
          render={({ field, fieldState }) => (
            <Selector
              options={employmentStatusOptions}
              size="medium"
              placeholder={t('patient.patientCheckoutPage.quotationForm.selectorPlaceholder')}
              labelText="Employment status"
              {...field}
              onChange={({ value }) => field.onChange(value)}
              validationError={fieldState.error?.message}
              testId="incomeType"
              defaultSelectedIndex={employmentStatusOptions.findIndex((option) => option.value === field.value)}
            />
          )}
        />
        <Controller
          name="annualSalary"
          control={quotationFormProps.control}
          render={({ field, fieldState }) => (
            <Input
              id="annualSalary"
              inputSize="medium"
              type="number"
              role="textbox"
              testId="annualSalary"
              leftIcon="pound"
              labelText="Annual income (before tax)"
              labelSize="small"
              preventInvalidCharacters
              isPositiveOnly
              errorText={fieldState.error?.message}
              placeholder={t('patient.patientCheckoutPage.quotationForm.annualSalaryPlaceholder')}
              {...field}
              onChange={(e) => {
                if (isPriceValid(e.target.value)) field.onChange(e);
              }}
            />
          )}
        />
      </div>
      <div className="flex flex-col gap-4">
        <Divider className="mt-6" margin="small" />
        <Heading variant="h6">{t('patient.patientCheckoutPage.quotationForm.housingTitle')}</Heading>
        <Controller
          name="residentialStatus"
          control={quotationFormProps.control}
          render={({ field, fieldState }) => (
            <Selector
              options={housingStatusOptions}
              size="medium"
              labelText="Housing status"
              placeholder={t('patient.patientCheckoutPage.quotationForm.selectorPlaceholder')}
              testId="residentialStatus"
              onChange={({ value }) => field.onChange(value)}
              validationError={fieldState.error?.message}
              defaultSelectedIndex={housingStatusOptions.findIndex((option) => option.value === field.value)}
            />
          )}
        />
        {!hideRentField && (
          <Controller
            name="rent"
            control={quotationFormProps.control}
            render={({ field, fieldState }) => (
              <Input
                id="rent"
                inputSize="medium"
                type="number"
                role="textbox"
                testId="rent"
                leftIcon="pound"
                labelText="Monthly housing costs"
                helperText={t('patient.patientCheckoutPage.quotationForm.rentHelperText')}
                placeholder={t('patient.patientCheckoutPage.quotationForm.rentPlaceholder')}
                labelSize="small"
                preventInvalidCharacters
                isPositiveOnly
                errorText={fieldState.error?.message}
                {...field}
                onChange={(e) => {
                  if (isPriceValid(e.target.value)) field.onChange(e);
                }}
              />
            )}
          />
        )}
      </div>
      <div className="flex flex-col gap-4">
        <Divider className="mt-6" margin="small" />
        <Heading variant="h6">{t('patient.patientCheckoutPage.quotationForm.loanInformationTitle')}</Heading>
        <Controller
          name="instalmentDate"
          control={quotationFormProps.control}
          render={({ field, fieldState }) => (
            <Selector
              options={instalmentDates}
              size="medium"
              labelText={t('patient.patientCheckoutPage.quotationForm.instalmentDateLabel')}
              placeholder={t('patient.patientCheckoutPage.quotationForm.selectorPlaceholder')}
              testId="instalmentDate"
              onChange={({ value }) => field.onChange(value)}
              validationError={fieldState.error?.message}
              defaultSelectedIndex={instalmentDates.findIndex((option) => parseInt(option.value) === field.value)}
            />
          )}
        />
      </div>
      <div className="flex felx-col gap-4 p-5 bg-backgroundNeutralSoft rounded-2xl mt-8">
        <Paragraph size="small">💡</Paragraph>
        <Paragraph size="small">{t('patient.patientCheckoutPage.quotationForm.softCheckMessage')}</Paragraph>
      </div>
      <Button
        type="button"
        size="medium"
        appearance="primary"
        mode="accent"
        text={t('patient.patientCheckoutPage.quotationForm.submit')}
        onClick={submitForm}
        className="mt-4"
      />
      <div className="flex flex-col gap-4 [&>*]:!text-foregroundNeutralSecondary mt-4">
        <Paragraph size="caption">
          <Trans
            i18nKey="patient.patientCheckoutPage.quotationForm.submitInfo.line1"
            components={{
              termsLink: (
                <a
                  target="_blank"
                  href="https://www.practihealth.co.uk/patient-terms"
                  className="underline"
                  rel="noreferrer"
                />
              ),
              privacyLink: (
                <a
                  target="_blank"
                  href="https://www.practihealth.co.uk/privacy"
                  className="underline"
                  rel="noreferrer"
                />
              ),
            }}
          />
        </Paragraph>
        <Paragraph size="caption">{t('patient.patientCheckoutPage.quotationForm.submitInfo.line2')}</Paragraph>
        <Paragraph size="caption">{t('patient.patientCheckoutPage.quotationForm.submitInfo.line3')}</Paragraph>
      </div>
      <div className="flex gap-2 items-center">
        <Paragraph size="caption" className="!text-foregroundNeutralSecondary">
          {t('patient.patientCheckoutPage.checkoutFinanceOptions.poweredBy')}
        </Paragraph>
        <img src="/images/patient/dividebuy-logo-small.svg" alt="logo" className="flex-grow:1 w-24" />
      </div>
    </form>
  );
};

const employmentStatusOptions = [
  { name: 'Employed full-time', value: 'Employed full-time' },
  { name: 'Employed part-time', value: 'Employed part-time' },
  { name: 'Self employed', value: 'Self employed' },
  { name: 'Pension', value: 'Pension' },
  { name: 'Other', value: 'Other' },
  { name: 'Director of a limited company', value: 'Director of a limited company' },
  { name: 'Unemployed', value: 'Unemployed' },
  { name: 'Retired, not working', value: 'Retired, not working' },
];

const housingStatusOptions = [
  { name: 'Outright owner', value: 'Yes, outright owner' },
  { name: 'Owner with mortgage', value: 'Yes, with mortgage' },
  { name: 'Renting', value: 'No, renting' },
  { name: 'Other', value: 'No, other' },
];

const getOrdinal = (n: number): string => {
  const suffixes = ['th', 'st', 'nd', 'rd'];
  const value = n % 100;
  return `${n}${suffixes[(value - 20) % 10] || suffixes[value] || suffixes[0]}`;
};

const instalmentDates = Array.from({ length: 31 }, (_, i) => ({
  name: getOrdinal(i + 1),
  value: i + 1,
})) as unknown as Option[];
