import { useState } from 'react';
import { TableCell } from '@library/components/atoms/TableCell';
import { TableHeader } from '@library/components/molecules';
import { convertToCurrency } from '@library/utils';
import {
  CellContext,
  ColumnDef,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';

import { formatCreatedAtValue } from '@practice/pages/PatientsPages/PatientsDashboard/PatientTable/utils';
import PatientRenderer from '@practice/pages/PatientsPages/PatientsDashboard/SubscriptionTable/CellRenderers/PatientRenderer';
import { PlanRenderer } from '@practice/pages/PatientsPages/PatientsDashboard/SubscriptionTable/CellRenderers/PlanRenderer/PlanRenderer';
import StatusRenderer from '@practice/pages/PatientsPages/PatientsDashboard/SubscriptionTable/CellRenderers/StatusRenderer';
import { GetSubscriptionOverviewArgs, SubscriptionOverview } from '@shared/data/types';

type CellFn<T> = CellContext<SubscriptionOverview, T>;

interface Props {
  subscriptionOverview: SubscriptionOverview[];
  practiceName: string;
  refetchSubscriptionOverview: (filters: Omit<GetSubscriptionOverviewArgs, 'practiceId'>) => void;
}

export const useTable = ({ subscriptionOverview }: Props) => {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [filter, setFilter] = useState('');
  const [pageIndex, setPageIndex] = useState(0);

  const createCell = (data: CellFn<string>) => <TableCell>{data.getValue()}</TableCell>;

  const columns: ColumnDef<SubscriptionOverview>[] = [
    {
      id: 'Patient name',
      accessorFn: (data: SubscriptionOverview) => {
        return data.patientName;
      },
      cell: PatientRenderer,
      size: 150,
    },
    {
      id: 'Payer name',
      accessorFn: (data: SubscriptionOverview) => {
        return data.payerName;
      },
      cell: createCell,
      size: 150,
    },
    {
      id: 'Payer email',
      accessorFn: (data: SubscriptionOverview) => data.userEmail,
      cell: createCell,
      size: 240,
    },
    {
      id: 'Plan',
      accessorFn: (data: SubscriptionOverview) => data.planName,
      cell: PlanRenderer,
      size: 240,
    },
    {
      id: 'Monthly fee',
      accessorFn: (data: SubscriptionOverview) => data.planPrice,
      cell: (data: CellFn<number>) => <TableCell>{convertToCurrency(data.getValue(), '£', true)}</TableCell>,
      size: 120,
    },
    {
      id: 'Start date',
      accessorFn: (data: SubscriptionOverview) => data.subscriptionStartDate,
      cell: (data: CellFn<string>) => <TableCell>{formatCreatedAtValue(data.getValue())}</TableCell>,
      size: 120,
    },
    {
      id: 'Status',
      accessorFn: (data: SubscriptionOverview) => data.subscriptionStatus,
      cell: StatusRenderer,
      size: 156,
    },
  ];

  const table = useReactTable({
    data: subscriptionOverview,
    state: {
      sorting,
      globalFilter: filter,
      pagination: {
        pageSize: 7,
        pageIndex,
      },
      columnVisibility: {
        email: false,
      },
    },
    defaultColumn: {
      header: TableHeader,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    columns,
  });

  return {
    table,
    setFilter,
    setSorting,
    setPageIndex,
  };
};
