import { PropsWithChildren } from 'react';
import clsx from 'clsx';

import { ITreatmentGuide, Practice } from '@shared/data/types';

import { CheckoutInterestedModule } from '../CheckoutInterestedModule';
import { CheckoutNotSureConfirmation } from '../CheckoutNotSureConfirmation';
import { CheckoutPaymentConfirmed } from '../CheckoutPaymentConfirmed';
import { ProceedWithTreatmentModule } from '../ProceedWithTreatmentModule';

export const CheckoutPatientDecisionContainer = ({
  children,
  maxWidth = true,
}: PropsWithChildren & { maxWidth?: boolean }) => (
  <div
    data-testid="patient-decision"
    className={clsx(
      'w-full grow bg-primary-surface p-6 xl:rounded-xl md:mb-8 flex flex-col gap-4 h-full mb-0 mt-6',
      maxWidth && 'md:max-w-[24rem]',
    )}
  >
    {children}
  </div>
);

export interface CheckoutPatientDecisionBlockProps {
  displayFinanceConfirmation: boolean;
  practice: Practice;
  financeEnabled: boolean;
  treatmentGuide: ITreatmentGuide;
  refetchTreatmentGuide: () => void;
  patientIsUnsure: boolean;
  displayPayInPracticeConfirmation: boolean;
  patientHasDeclined: boolean;
  displayDecisionOptions: boolean;
  setDisplayDecisionOptions: (display: boolean) => void;
  displayInterestedConfirmation: boolean;
  goAheadCheckedDefault: boolean;
  financeOnly: boolean;
  displayPricing?: boolean;
  enablePayment?: boolean;
  treatmentGuideId: string;
}

export const CheckoutPatientDecisionBlock = ({
  displayFinanceConfirmation,
  treatmentGuide,
  financeEnabled,
  refetchTreatmentGuide,
  practice,
  patientIsUnsure,
  displayPayInPracticeConfirmation,
  patientHasDeclined,
  displayDecisionOptions,
  setDisplayDecisionOptions,
  displayInterestedConfirmation,
  goAheadCheckedDefault,
  financeOnly,
  displayPricing,
  enablePayment,
  treatmentGuideId,
}: CheckoutPatientDecisionBlockProps) => {
  if (displayDecisionOptions) {
    return (
      <CheckoutPatientDecisionContainer>
        <ProceedWithTreatmentModule
          financeEnabled={financeEnabled}
          refetchTreatmentGuide={refetchTreatmentGuide}
          treatmentGuideId={treatmentGuideId}
          treatmentPrice={parseInt(treatmentGuide.price || '0')}
          treatments={treatmentGuide.treatments}
          practiceName={practice.name}
          setDisplayDecisionOptions={setDisplayDecisionOptions}
          goAheadCheckedDefault={goAheadCheckedDefault}
          financeOnly={financeOnly}
          displayPricing={displayPricing}
          enablePayment={enablePayment}
        />
      </CheckoutPatientDecisionContainer>
    );
  }
  if (displayInterestedConfirmation) {
    return (
      <CheckoutPatientDecisionContainer>
        <CheckoutInterestedModule practiceNumber={practice.phone} email={practice.email} />
      </CheckoutPatientDecisionContainer>
    );
  }
  if (displayFinanceConfirmation || displayPayInPracticeConfirmation) {
    return (
      <CheckoutPatientDecisionContainer>
        <CheckoutPaymentConfirmed
          practiceName={practice.name}
          practiceNumber={practice.phone}
          email={practice.email}
          hasSeenConfirmation
          payInPractice={displayPayInPracticeConfirmation}
        />
      </CheckoutPatientDecisionContainer>
    );
  }
  return patientIsUnsure || patientHasDeclined ? (
    <CheckoutPatientDecisionContainer>
      <CheckoutNotSureConfirmation
        patientHasDeclined={patientHasDeclined}
        practiceName={practice.name}
        practiceNumber={practice.phone}
        email={practice.email}
        setDisplayDecisionOptions={setDisplayDecisionOptions}
      />
    </CheckoutPatientDecisionContainer>
  ) : (
    <CheckoutPatientDecisionContainer>
      <ProceedWithTreatmentModule
        financeEnabled={financeEnabled}
        refetchTreatmentGuide={refetchTreatmentGuide}
        treatmentGuideId={treatmentGuideId}
        treatmentPrice={parseInt(treatmentGuide.price || '0')}
        treatments={treatmentGuide.treatments}
        practiceName={practice.name}
        setDisplayDecisionOptions={setDisplayDecisionOptions}
        goAheadCheckedDefault={goAheadCheckedDefault}
        financeOnly={financeOnly}
        displayPricing={displayPricing}
        enablePayment={enablePayment}
      />
    </CheckoutPatientDecisionContainer>
  );
};
