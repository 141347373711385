import { t } from 'i18next';
import * as z from 'zod';

import { validations } from '@shared/utils/validations';
const townRegex = /^[0-9a-zA-Z- ]+$/;
const addressLineRegex = /^[0-9a-zA-Z,.\-/ ]+$/;
export const baseAddressSchema = z.object({
  addressline1: z
    .string({ message: t('plans.patientPayerInfo.billingInfo.error.addressline1') })
    .trim()
    .min(5, { message: t('plans.patientPayerInfo.billingInfo.error.addressline1') })
    .max(100, { message: t('plans.patientPayerInfo.billingInfo.error.addressline1') })
    .refine((value) => addressLineRegex.test(value), {
      message: t('plans.patientPayerInfo.billingInfo.error.addressline1'),
    }),

  addressline2: z
    .ostring()
    .refine((value) => !value || (addressLineRegex.test(value) && value.length <= 100 && value.length >= 5), {
      message: t('plans.patientPayerInfo.billingInfo.error.addressline2'),
    }),

  addressline3: z
    .ostring()
    .refine((value) => !value || (addressLineRegex.test(value) && value.length <= 100 && value.length >= 5), {
      message: t('plans.patientPayerInfo.billingInfo.error.addressline3'),
    }),

  posttown: z
    .string({ message: t('plans.patientPayerInfo.billingInfo.error.townOrCityName') })
    .trim()
    .min(2, { message: t('plans.patientPayerInfo.billingInfo.error.townOrCityName') })
    .max(50, { message: t('plans.patientPayerInfo.billingInfo.error.townOrCityName') })
    .refine((value) => townRegex.test(value), {
      message: t('plans.patientPayerInfo.billingInfo.error.townOrCityName'),
    }),

  postcode: validations.postcode,
});

export const addressSchema = baseAddressSchema.strict();
