import { useEffect, useMemo, useState } from 'react';
import { Trans } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Heading, Icon, Paragraph } from '@library/components/atoms';
import { Button } from '@library/components/molecules';
import { RadioGroup } from '@library/components/molecules/RadioGroup';
import useShowFinanceOptions from '@library/hooks/useShowFinanceOptions/useShowFinanceOptions';
import { t } from 'i18next';

import FinanceModal from '@patient/components/FinanceModal';
import { TreatmentNames } from '@practice/pages/PatientsPages/PatientsDashboard/PatientTable/types';
import { Accordian } from '@shared/components/Accordian';
import { OffCanvas } from '@shared/components/OffCanvas/OffCanvas';
import { Spinner } from '@shared/components/Spinner';
import { Typography } from '@shared/components/Typography';
import { MaxAvailableInstalment, MinAvailableInstalment } from '@shared/content-blocks/FinanceOptionsModule';
import { Disclaimer } from '@shared/content-blocks/FinanceOptionsModule/Disclaimer';
import { useGetPracticeInstalments } from '@shared/data/practice/hooks';
import { DivideBuyInstalment, PricingType } from '@shared/data/types';
import { useEligibilityChecker } from '@shared/hooks/useEligibilityChecker/useEligibilityChecker';
import { DividebuyLoanApplicationAction } from '@shared/stores/dividebuyLoanApplicationStore';
import { divideBuyCurrencyFormatter } from '@shared/utils/numberFormatter';
import { PatientMixpanel } from '@shared/utils/patientMixpanel';
import { dividebuyLoanApplicationPage } from '@shared/utils/urls';

export interface CheckoutFinanceOptionsProps {
  price: number;
  deposit?: number;
  displayPricing?: boolean;
  treatments: string[];
  practiceName?: string;
  pricingType?: PricingType | null;
  selectedOption: string;
  setSelectedTerm: DividebuyLoanApplicationAction['setData'];
}

export const CheckoutFinanceOptions = ({
  price,
  deposit,
  displayPricing,
  selectedOption,
  setSelectedTerm,
  pricingType,
  practiceName,
}: CheckoutFinanceOptionsProps) => {
  const navigate = useNavigate();
  const [offCanvasMenuOpen, setOffCanvasMenuOpen] = useState<boolean>(false);
  const treatmentGuideId = localStorage.getItem('treatmentGuideId') || '';
  const practiceId = treatmentGuideId.split(':')[0];
  const finalBalance = price - (deposit || 0);

  const {
    data: instalments,
    isLoading,
    refetch: getInstalments,
  } = useGetPracticeInstalments({
    amount: String(finalBalance / 100),
    practiceId,
  });

  useEffect(() => {
    if (finalBalance && practiceId) getInstalments();
  }, [finalBalance, practiceId, getInstalments]);

  const instalmentsData = useMemo(() => instalments?.data.terms || [], [instalments?.data]);

  const maxLoanAmount = useMemo(
    () =>
      Math.max(
        ...(
          instalmentsData.filter(
            ({ max_price, type }) => max_price !== null && type !== 'representative_example',
          ) as MaxAvailableInstalment[]
        ).map(({ max_price }) => max_price),
      ).toString(),
    [instalmentsData],
  );

  const minLoanAmount = useMemo(
    () =>
      Math.min(
        ...(
          instalmentsData.filter(
            ({ min_price, type }) => min_price !== null && type !== 'representative_example',
          ) as MinAvailableInstalment[]
        ).map(({ min_price }) => min_price),
      ).toString(),
    [instalmentsData],
  );

  const availableInstalments = useMemo(
    () => instalmentsData.filter(({ available, type }) => type !== 'representative_example' && available != false),
    [instalmentsData],
  );

  const representativeExample = instalmentsData.find(
    (item: DivideBuyInstalment) => item.type === 'representative_example',
  );

  const showOptions = useShowFinanceOptions(
    Boolean(availableInstalments.length),
    finalBalance,
    maxLoanAmount,
    minLoanAmount,
  );

  const isAboveMax = finalBalance >= parseFloat(maxLoanAmount!);

  const { financeModalRef, openEligibilityChecker } = useEligibilityChecker();

  const onOpenEligibilityChecker = () => {
    openEligibilityChecker();
    PatientMixpanel.track('Finance eligibility checked', {
      'Practice name': practiceName,
      'Treatment name': TreatmentNames,
      'Treatment price': String(finalBalance / 100),
      'Pricing type': pricingType,
    });
  };

  useEffect(() => {
    if (!selectedOption && availableInstalments.length) {
      const { interest_rate, term_length } = availableInstalments[0];
      setSelectedTerm({ interestRate: interest_rate, term: term_length });
    }
  }, [selectedOption, setSelectedTerm, availableInstalments]);

  return (
    <div>
      <Heading variant="h6" className="mb-4">
        {t('patient.patientCheckoutPage.checkoutFinanceOptions.heading')}
      </Heading>
      <Paragraph size="small" className="mb-4">
        {t('patient.patientCheckoutPage.checkoutFinanceOptions.subHeading')}
      </Paragraph>
      <div className="flex gap-2 items-center">
        <Paragraph size={'caption'} className="!text-foregroundNeutralSecondary">
          {t('patient.patientCheckoutPage.checkoutFinanceOptions.poweredBy')}
        </Paragraph>
        <img src="/images/patient/dividebuy-logo-small.svg" alt="logo" className="flex-grow:1 w-24" />
      </div>
      {displayPricing !== false && (
        <>
          {availableInstalments && maxLoanAmount && !isLoading ? (
            <>
              {showOptions ? (
                <div className="mt-4">
                  <RadioGroup
                    selectedOption={selectedOption}
                    name="term"
                    optionBorder
                    size="medium"
                    // TDOD: Move options to a memoised function
                    options={availableInstalments.map(
                      ({ monthly_instalment_cost, term_length, interest_rate, total_to_pay }) => {
                        const termValue = `${interest_rate}-${term_length}`;
                        return {
                          helperText: t(
                            'patient.patientCheckoutPage.checkoutFinanceOptions.instalmentOption.subHeading',
                            {
                              interest_rate,
                              total_to_pay,
                            },
                          ),
                          labelText: t('patient.patientCheckoutPage.checkoutFinanceOptions.instalmentOption.heading', {
                            monthly_instalment_cost,
                            term_length,
                          }),
                          labelBadge: `${interest_rate}% APR`,
                          labelBadgeAppearance: interest_rate === 0 ? 'blue' : 'salmon',
                          name: 'term',
                          /** The value passed to each option is a combination of interest_rate and term_length because
                           * in the Dividebuy testing sandbox two instalments can be returned for the same term_length
                           * this is not the case in production but this safegurards us for the test environment
                           */
                          value: termValue,
                          onChange: () => setSelectedTerm({ interestRate: interest_rate, term: term_length }),
                          hideRadio: pricingType === PricingType.ESTIMATED,
                          checkedContent:
                            pricingType === PricingType.ESTIMATED ? null : (
                              <>
                                <Button
                                  className="w-full"
                                  size="medium"
                                  text="Check my eligibility"
                                  appearance="primary"
                                  mode="accent"
                                  onClick={() => navigate(dividebuyLoanApplicationPage)}
                                />
                                <div className="flex gap-2 items-start mt-5">
                                  <Icon
                                    className="p-[0.0625rem]"
                                    size="small"
                                    iconName="info"
                                    color="text-foregroundNeutralSecondary"
                                  />
                                  <Paragraph size="small" className="!text-foregroundNeutralSecondary">
                                    {t('patient.patientCheckoutPage.checkoutFinanceOptions.eligibilityInfo')}
                                  </Paragraph>
                                </div>
                              </>
                            ),
                        };
                      },
                    )}
                  />
                  <div className="mt-4">
                    <Disclaimer repExample={representativeExample} instalments={availableInstalments} />
                  </div>
                  {pricingType === PricingType.ESTIMATED ? (
                    <div className="p-5 bg-backgroundNeutralSoft rounded-xl mt-10">
                      <Trans
                        i18nKey="patient.myGuidePage.loanCalculatorBlock.links.eligible"
                        components={{
                          typography: <Paragraph size="medium" />,
                          eligibilityChecker: (
                            <Button
                              size="medium"
                              mode="accent"
                              appearance="ghost"
                              className="underline decoration-solid !p-0 !font-normal !text-logo-bluebell-100 leading-6 tracking-[0.32px] !h-auto"
                              text="check if you're eligible"
                              onClick={onOpenEligibilityChecker}
                            />
                          ),
                        }}
                      />
                    </div>
                  ) : (
                    <div className="bg-primary-light p-4 rounded-lg w-full flex gap-2 mt-4">
                      <span>⚠️</span>
                      <Paragraph size="small" className="!text-foregroundNeutralPrimary">
                        {t('patient.patientCheckoutPage.checkoutFinanceOptions.borrowingDisclaimer')}
                      </Paragraph>
                    </div>
                  )}
                  <Button
                    className="mt-6 w-full mb-4"
                    mode="accent"
                    appearance="subtle"
                    leftIcon="help"
                    size="large"
                    iconSize="small"
                    onClick={() => setOffCanvasMenuOpen(true)}
                    testId="view-finance-faqs"
                    text={t('patient.patientCheckoutPage.learnMore')}
                  />
                </div>
              ) : (
                <Trans
                  i18nKey={
                    isAboveMax
                      ? 'patient.myGuidePage.loanCalculatorBlock.financeOptions.aboveMaximum'
                      : 'patient.myGuidePage.loanCalculatorBlock.financeOptions.belowMinimum'
                  }
                  components={{
                    typography: <Typography variant="b16" element="span" className="text-primary-dark" />,
                  }}
                  values={{
                    max_loan_amount: divideBuyCurrencyFormatter(parseFloat(maxLoanAmount!)),
                    min_loan_amount: divideBuyCurrencyFormatter(parseFloat(minLoanAmount!)),
                  }}
                />
              )}
            </>
          ) : (
            <div className="relative w-full flex items-center py-4">
              <Spinner />
            </div>
          )}
        </>
      )}
      <FinanceModal financeModalRef={financeModalRef} price={finalBalance / 100} />
      <OffCanvas setIsOpen={setOffCanvasMenuOpen} isOpen={offCanvasMenuOpen}>
        <Accordian
          icons={{ open: 'minus', close: 'plus' }}
          className="text-primary-dark"
          header={t('patient.treatmentsPage.faq.title')}
          items={Array.from({ length: 14 }, (_, index) => {
            const itemIndex = (index + 1) as unknown as 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 14;
            if (itemIndex === 3) {
              return {
                title: t(`patient.financePage.faqBlock.question${itemIndex}.text`),
                description: (
                  <>
                    {t('patient.financePage.faqBlock.question3.answer')}
                    <ul role="list" className="list-disc ml-6 my-2">
                      <li>{t('patient.financePage.faqBlock.question3.limitMonth12')}</li>
                      <li>{t('patient.financePage.faqBlock.question3.limitMonth24')}</li>
                      <li>{t('patient.financePage.faqBlock.question3.limitMonth36')}</li>
                    </ul>
                    {t('patient.financePage.faqBlock.question3.info')}
                  </>
                ),
              };
            } else if (itemIndex === 10) {
              return {
                title: t(`patient.financePage.faqBlock.question${itemIndex}.text`),
                description: (
                  <p>
                    {t('patient.financePage.faqBlock.question10.answer')}
                    <a
                      className="text-primary-main underline decoration-2 underline-offset-4"
                      target="_blank"
                      href={t('patient.financePage.faqBlock.question10.link.href')}
                      rel="noreferrer"
                    >
                      {t('patient.financePage.faqBlock.question10.link.text')}
                    </a>
                  </p>
                ),
              };
            } else {
              return {
                title: t(`patient.financePage.faqBlock.question${itemIndex}.text`),
                description: t(`patient.financePage.faqBlock.question${itemIndex}.answer`),
              };
            }
          })}
        />
      </OffCanvas>
    </div>
  );
};
