import { memo } from 'react';
import { Heading } from '@library/components/atoms/Heading';
import { Illustration, IllustrationProps } from '@library/components/atoms/Illustration';
import { Paragraph } from '@library/components/atoms/Paragraph/Paragraph';
import { Button, ButtonProps } from '@library/components/molecules';
import clsx from 'clsx';

import { getHeadingVariant, getParagraphSize, getSizeClasses } from './utils';

const baseClasses = `
  w-full
  flex
  flex-col
  items-center
  justify-center
  rounded-2xl
  bg-white
  text-center
`;

const backgroundClasses = `
  !bg-backgroundNeutralSoft
  !border
  !border-borderNeutralDefault
`;

export interface EmptyProps {
  background?: boolean;
  size: 'small' | 'medium' | 'large';
  illustration: IllustrationProps['type'];
  title: string;
  description: string;
  buttonText?: string;
  handleButtonClick?: () => void;
  buttonIcon?: ButtonProps['leftIcon'];
}

export const Empty = memo(
  ({ size, background, illustration, title, description, buttonText, buttonIcon, handleButtonClick }: EmptyProps) => {
    const sizeClasses = getSizeClasses(size);
    const headingVariant = getHeadingVariant(size);
    const paragraphSize = getParagraphSize(size);

    return (
      <div data-testid="empty-wrapper" className={clsx(baseClasses, sizeClasses, background && backgroundClasses)}>
        <div className={clsx(size === 'small' ? 'mb-4' : 'mb-5')}>
          <Illustration size={size} type={illustration} />
        </div>
        <div
          className={clsx(
            'flex flex-col items-center justify-center',
            size === 'small' ? 'gap-y-1 mb-4' : 'gap-y-2 mb-6',
          )}
        >
          <Heading variant={headingVariant} className="!text-foregroundNeutralPrimary">
            {title}
          </Heading>
          <Paragraph size={paragraphSize} className="max-w-[20rem] !text-foregroundNeutralSecondary">
            {description}
          </Paragraph>
        </div>
        {buttonText && handleButtonClick && (
          <Button
            size={size}
            text={buttonText}
            appearance="primary"
            mode="accent"
            onClick={handleButtonClick}
            leftIcon={buttonIcon}
          />
        )}
      </div>
    );
  },
);

Empty.displayName = 'Empty';
