import { TableCell } from '@library/components/atoms/TableCell';
import { Tag } from '@library/components/molecules';
import { CellContext } from '@tanstack/react-table';
import { ICellRendererParams } from 'ag-grid-community';

import { Tooltip } from '@shared/components/Tooltip';
import { PatientOverview } from '@shared/data/types';

import { TextRenderer } from '../TextRenderer';

export const TreatmentRenderer = ({ value, data }: ICellRendererParams<PatientOverview>) => {
  return (
    <TableCell>
      {data?.treatments.length === 1 ? (
        data.treatments[0].name
      ) : (
        <div className="flex gap-2">
          <TextRenderer text={value} />
          <Tooltip
            iconName="info"
            iconSize={16}
            iconClassName="stroke-primary-dark"
            text={
              <ul className="list-disc ml-6 text-primary-dark">
                {data?.treatments.map((treatment) => (
                  <li key={treatment.name}>{treatment.name}</li>
                ))}
              </ul>
            }
            className="w-[260px]"
          />
        </div>
      )}
    </TableCell>
  );
};

export const TreatmentRendererNewDS = (props: CellContext<PatientOverview, string>) => {
  const treatments = props.row.original.treatments;
  return (
    <TableCell>
      <div className="flex gap-2">
        {treatments.length
          ? treatments
              .slice(0, 2)
              .map((treatment) => <Tag size="medium" key={treatment.name} template="OUTLINE" text={treatment.name} />)
          : null}
        {treatments.length > 2 && <Tag size="medium" text={`+${treatments.length - 2}`} template="OUTLINE" />}
      </div>
    </TableCell>
  );
};
