import { memo, useMemo } from 'react';
import { DataTable, InfoMessage } from '@library/components/molecules';
import { Empty } from '@library/components/organisms';
import { Table } from '@tanstack/react-table';
import { t } from 'i18next';

import { TableFilters } from '@practice/pages/PatientsPages/PatientsDashboard/PatientTable/TableFiltersNewDS/TableFilters';
import {
  GetPatientOverviewArgs,
  IPractitioner,
  PatientAction,
  PatientOverview,
  PatientTableState,
  PatientTableView,
} from '@shared/data/types';
import { useTreatmentGuideContext } from '@shared/hooks';

import { useTable } from './useTable';

export interface PatientTableProps {
  patients: PatientOverview[];
  patientsLoading: boolean;
  practitioners?: IPractitioner[];
  activeFilters: ActiveFilters;
  refetchPatientOverview: (filters: Omit<GetPatientOverviewArgs, 'practiceId'>) => void;
  practiceName: string;
  patientTableState?: PatientTableState;
  handleDownloadCsv?: (filters: Omit<GetPatientOverviewArgs, 'practiceId'>) => void;
}

export type ActiveFilters = {
  practitioner?: string;
  patientAction?: PatientAction;
  archived?: boolean;
  view?: PatientTableView;
};

export const PatientsTable = memo(
  ({
    patients,
    patientsLoading,
    practitioners,
    activeFilters,
    refetchPatientOverview,
    practiceName,
    handleDownloadCsv,
  }: PatientTableProps) => {
    const isFilterActive = useMemo(
      () => activeFilters.archived || activeFilters.patientAction || activeFilters.practitioner,
      [activeFilters],
    );

    const treatmentGuideContext = useTreatmentGuideContext();

    const openTreatmentGuideModal = () =>
      treatmentGuideContext?.setTreatmentGuideModalData({
        treatmentGuideId: '',
        isOpen: true,
      });

    const { setFilter, setPageIndex, table } = useTable({
      patientOverview: patients,
      practiceName,
      refetchPatientOverview,
      patientTableView: activeFilters.view,
    });

    const displayedRowCount = table.getFilteredRowModel().rows.length;

    let patientTableState: PatientTableState | undefined;

    const noGuidesExist =
      activeFilters.view !== PatientTableView.FINANCE && !isFilterActive && !patientsLoading && !displayedRowCount;

    if (noGuidesExist) {
      patientTableState = PatientTableState.NO_GUIDES_EXIST;
    } else if (!displayedRowCount) {
      patientTableState = PatientTableState.NO_RESULTS;
    }

    return (
      <>
        <TableFilters
          setFilter={setFilter}
          displayedRowCount={displayedRowCount}
          guidePractitioners={practitioners}
          activeFilters={activeFilters}
          setActiveFilters={refetchPatientOverview}
          patientTableView={activeFilters.view}
          handleDownloadCsv={handleDownloadCsv}
        />

        <div data-testid="patient-table-wrapper" className="w-full">
          {activeFilters.view === PatientTableView.FINANCE && <InfoMessage />}
          <DataTable
            table={table as Table<unknown>}
            displayedRowCount={displayedRowCount}
            setPageIndex={setPageIndex}
            loading={patientsLoading}
            isEmpty={noGuidesExist}
            noResults={patientTableState === PatientTableState.NO_RESULTS}
            emptyState={
              <Empty
                background
                handleButtonClick={openTreatmentGuideModal}
                buttonText={t('practice.patientDashboard.noGuidesCreated.cta')}
                description={t('practice.patientDashboard.noGuidesCreated.description')}
                illustration="confused1"
                size="large"
                title={t('practice.patientDashboard.noGuidesCreated.title')}
                buttonIcon="add"
              />
            }
          />
        </div>
      </>
    );
  },
);

PatientsTable.displayName = 'PatientsTable';
