import { ChangeEvent } from 'react';
import { Radio, RadioProps } from '@library/components/molecules/Radio';
import { Size } from '@library/types';

export interface RadioGroupProps {
  options: RadioProps[];
  name: string;
  size: Extract<Size, 'small' | 'medium' | 'large'>;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  selectedOption: string;
  optionBorder?: boolean;
}

export const RadioGroup = ({
  size = 'medium',
  name,
  options,
  onChange,
  selectedOption,
  optionBorder,
}: RadioGroupProps) => (
  <div className="flex flex-col gap-4">
    {options?.length &&
      options?.map((option) => (
        <Radio
          key={option.value}
          disabled={option.disabled}
          name={name}
          value={option.value}
          radioSize={size}
          labelText={option.labelText}
          helperText={option.helperText}
          onChange={onChange || option.onChange}
          checked={option.value === selectedOption}
          border={optionBorder}
          checkedContent={option.checkedContent}
          labelBadge={option.labelBadge}
          labelBadgeAppearance={option.labelBadgeAppearance}
          hideRadio={option.hideRadio}
        />
      ))}
  </div>
);

RadioGroup.displayName = 'RadioGroup';
