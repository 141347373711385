import { Divider, Icon, Paragraph } from '@library/components/atoms';
import { Empty } from '@library/components/organisms';
import { convertToCurrency } from '@library/utils';
import { t } from 'i18next';

import { Quote as QuoteI } from '@shared/data/types';

import { Button } from '../Button';
import { Label } from '../Label';

interface QuoteProps {
  details?: QuoteI['details'];
  interestRate?: number;
  onContinueToDividebuy: (quoteId: string) => void;
  quoteId: QuoteI['id'];
  postSelectQuoteIsLoading: boolean;
}

export const Quote = ({
  details,
  interestRate,
  onContinueToDividebuy,
  quoteId,
  postSelectQuoteIsLoading,
}: QuoteProps) => {
  if (!details || interestRate === undefined)
    return (
      <div className="mb-6">
        <Empty
          illustration="confused1"
          size="large"
          title={t('plans.error.title')}
          description={t('patient.dividebuyLoanApplicationPage.eligibilityResults.errorMessage')}
        />
      </div>
    );

  const { total_instalments, loan_amount, total_payable_today, regular_instalment_amount } = details;
  return (
    <div className="flex flex-col self-stretch border border-borderNeutralDefaultDisabled p-5 rounded-2xl border-solid">
      <Label
        element="div"
        size="medium"
        labelText={t('patient.dividebuyLoanApplicationPage.quote.heading', {
          total_instalments,
          interest_rate: interestRate,
        })}
      />
      <Divider margin="large" />
      <div className="flex flex-col gap-2">
        <div className="flex justify-between">
          <Paragraph size="medium">{t('patient.dividebuyLoanApplicationPage.quote.totalCredit')}</Paragraph>
          <Label
            testId="totalCredit"
            element="div"
            size="medium"
            labelText={convertToCurrency(loan_amount, '£', true)}
          />
        </div>
        <div className="flex justify-between">
          <Paragraph size="medium">{t('patient.dividebuyLoanApplicationPage.quote.initialPaymentToday')}</Paragraph>
          <Label
            testId="initialPaymentToday"
            element="div"
            size="medium"
            labelText={convertToCurrency(total_payable_today, '£', true)}
          />
        </div>
        <div className="flex justify-between">
          <Paragraph size="medium">{t('patient.dividebuyLoanApplicationPage.quote.monthlyPayment')}</Paragraph>
          <Label
            testId="monthlyPayment"
            element="div"
            size="medium"
            labelText={convertToCurrency(regular_instalment_amount, '£', true)}
          />
        </div>
        <div className="flex justify-between">
          <Paragraph size="medium">{t('patient.dividebuyLoanApplicationPage.quote.repaymentTerm')}</Paragraph>
          <Label testId="repaymentTerm" element="div" size="medium" labelText={`${total_instalments} months`} />
        </div>
        <div className="flex justify-between">
          <Paragraph size="medium">{t('patient.dividebuyLoanApplicationPage.quote.apr')}</Paragraph>
          <Label testId="apr" element="div" size="medium" labelText={`${interestRate}%`} />
        </div>
      </div>
      <Button
        className="my-6"
        size="medium"
        appearance="primary"
        mode="accent"
        text="Continue with DivideBuy"
        disabled={postSelectQuoteIsLoading}
        onClick={() => onContinueToDividebuy(quoteId)}
      />
      <div className="flex gap-2 items-start">
        <Icon className="p-[0.0625rem]" size="small" iconName="info" color="text-foregroundNeutralSecondary" />
        <Paragraph size="small" className="!text-foregroundNeutralSecondary">
          {t('patient.dividebuyLoanApplicationPage.quote.redirect')}
        </Paragraph>
      </div>
    </div>
  );
};
