import {
  Accessor,
  DeletePracticeMembersArgs,
  GetAttachmentsArgs,
  GetPatientOverview,
  GetPatientOverviewArgs,
  GetPayoutOverviewArgs,
  GetSubscriptionOverviewArgs,
  PatchPracticeArgs,
  PatchPracticeMembersArgs,
  PatchPractitionersArgs,
  PatchTreatmentGuideArgs,
  PatchUserArgs,
  Patient,
  Payer,
  PostAttachments,
  PostAttachmentsArgs,
  PostDividebuyRedirectArgs,
  PostPlanSubscriptionArgs,
  PostPracticeArgs,
  PostPracticeMembers,
  PostPracticeMembersArgs,
  PostPractitioners,
  PostPractitionersArgs,
  PostQuotationBody,
  PostSelectQuoteBody,
  PostTreatmentGuides,
  PostTreatmentGuidesArgs,
  Subscription,
} from '@shared/data/types';
import { patientFetch, practiceFetch as fetch, practiceFetch, unprotectedFetch } from '@shared/utils/fetch';
import { newApiBaseUrl, newApiUrls } from '@shared/utils/urls';

import { accessFetch } from './queries';

export const fetchers = {
  // mutations for new BE API

  PATCH_PRACTICE: ({ practiceId, ...data }: PatchPracticeArgs): Promise<void> =>
    fetch({
      baseURL: newApiBaseUrl,
      method: 'PATCH',
      url: `${newApiUrls.practices}/${practiceId}`,
      data,
    }),
  POST_PRACTITIONERS: (data: PostPractitionersArgs): Promise<PostPractitioners> =>
    fetch({
      baseURL: newApiBaseUrl,
      method: 'POST',
      url: `${newApiUrls.practitioners}`,
      data,
    }),
  POST_PRACTICE_MEMBERS: ({ practiceId, ...data }: PostPracticeMembersArgs): Promise<PostPracticeMembers> =>
    fetch({
      baseURL: newApiBaseUrl,
      method: 'POST',
      url: `${newApiUrls.practices}/${practiceId}${newApiUrls.members}`,
      data,
    }),
  PATCH_PRACTICE_MEMBERS: ({
    practiceId,
    notificationsEnabled,
    resendInvite,
    userId,
    role,
    hasOnboarded,
  }: PatchPracticeMembersArgs): Promise<void> =>
    fetch({
      baseURL: newApiBaseUrl,
      method: 'PATCH',
      url: `${newApiUrls.practices}/${practiceId}${newApiUrls.members}/${userId}`,
      data: { notificationsEnabled, resendInvite, role, hasOnboarded },
    }),
  DELETE_PRACTICE_MEMBER: ({ practiceId, userId }: DeletePracticeMembersArgs): Promise<void> =>
    fetch({
      baseURL: newApiBaseUrl,
      method: 'DELETE',
      headers: {},
      url: `${newApiUrls.practices}/${practiceId}${newApiUrls.members}/${userId}`,
    }),
  PATCH_PRACTITIONERS: ({ practitionerId, ...data }: PatchPractitionersArgs): Promise<void> =>
    fetch({
      baseURL: newApiBaseUrl,
      method: 'PATCH',
      url: `${newApiUrls.practitioners}/${practitionerId}`,
      data,
    }),
  POST_TREATMENT_GUIDES: (data: PostTreatmentGuidesArgs): Promise<PostTreatmentGuides> =>
    fetch({
      baseURL: newApiBaseUrl,
      method: 'POST',
      url: `${newApiUrls.treatmentGuides}`,
      data,
    }),
  PATCH_TREATMENT_GUIDE: ({ id, ...data }: PatchTreatmentGuideArgs, accessor: Accessor): Promise<void> => {
    return accessFetch(accessor)({
      baseURL: newApiBaseUrl,
      method: 'PATCH',
      url: `${newApiUrls.treatmentGuides}/${id}`,
      data,
    });
  },
  POST_AUTH_S3_PUT_OBJECT: (data: PostAttachmentsArgs): Promise<PostAttachments & PostAttachmentsArgs> =>
    fetch({
      baseURL: newApiBaseUrl,
      method: 'POST',
      url: `${newApiUrls.auth}${newApiUrls.s3}/put-object`,
      data,
    }),
  GET_AUTH_S3_PUT_OBJECT: (data: GetAttachmentsArgs, accessor: Accessor): Promise<{ data: { url: string } }> =>
    accessFetch(accessor)({
      baseURL: newApiBaseUrl,
      method: 'POST',
      url: `${newApiUrls.auth}${newApiUrls.s3}/get-object`,
      data,
    }),
  GET_PATIENT_OVERVIEW: ({ practiceId, ...data }: GetPatientOverviewArgs): Promise<GetPatientOverview> =>
    fetch({
      baseURL: newApiBaseUrl,
      method: 'GET',
      url: `${newApiUrls.practices}/${practiceId}${newApiUrls.patientOverview}`,
      params: {
        ...data,
      },
    }),
  GET_PATIENT_OVERVIEW_CSV: ({ practiceId, ...data }: GetPatientOverviewArgs): Promise<string> =>
    fetch({
      baseURL: newApiBaseUrl,
      method: 'GET',
      headers: {
        Accept: 'text/csv',
      },
      url: `${newApiUrls.practices}/${practiceId}${newApiUrls.patientOverview}`,
      params: {
        ...data,
      },
    }),
  POST_DIVIDEBUY_REDIRECT: (data: PostDividebuyRedirectArgs) =>
    patientFetch({
      baseURL: newApiBaseUrl,
      method: 'POST',
      url: `${newApiUrls.auth}${newApiUrls.dividebuy}${newApiUrls.redirect}`,
      data,
    }),
  POST_MAGIC_LINK: (data: { email: string }, accessor: Accessor) => {
    const fetch = accessor === Accessor.PRACTICE_MEMBER ? practiceFetch : unprotectedFetch;
    return fetch({
      baseURL: newApiBaseUrl,
      method: 'POST',
      url: `${newApiUrls.auth}${newApiUrls.magicLink}`,
      data,
    });
  },
  POST_DIVIDEBUY_SOFT_SEARCH: (data: { amount: number }) =>
    patientFetch({
      baseURL: newApiBaseUrl,
      method: 'POST',
      url: `${newApiUrls.auth}${newApiUrls.dividebuy}${newApiUrls.softSearch}`,
      data,
    }),
  POST_PRACTICE: (data: PostPracticeArgs) =>
    practiceFetch({
      baseURL: newApiBaseUrl,
      method: 'POST',
      url: `${newApiUrls.practices}`,
      data,
    }),
  PATCH_AUTH_USER: ({ hasOnboarded }: PatchUserArgs): Promise<void> => {
    return practiceFetch({
      baseURL: newApiBaseUrl,
      method: 'PATCH',
      url: `${newApiUrls.auth}${newApiUrls.user}`,
      data: {
        hasOnboarded,
      },
    });
  },
  GET_ADDRESSES: ({ postcode }: { postcode: string }): Promise<{ data: { addresses: [] } }> =>
    unprotectedFetch({
      baseURL: newApiBaseUrl,
      url: newApiUrls.addresses(postcode),
    }),
  POST_DIVIDEBUY_QUOTATION: (data: PostQuotationBody) =>
    patientFetch({
      baseURL: newApiBaseUrl,
      method: 'POST',
      url: newApiUrls.quotation,
      data,
    }),
  POST_PLAN_SUBSCRIPTION: (data: { patient: Patient; payer: Payer; subscription: Subscription }) =>
    unprotectedFetch({
      baseURL: newApiBaseUrl,
      method: 'POST',
      url: `${newApiUrls.planSubscription}`,
      data,
    }),
  POST_DIVIDEBUY_SELECT_QUOTE: (data: PostSelectQuoteBody) =>
    patientFetch({
      baseURL: newApiBaseUrl,
      method: 'POST',
      url: newApiUrls.selectQuote,
      data,
    }),
  GET_SUBSCRIPTION_OVERVIEW: (data: GetSubscriptionOverviewArgs) =>
    unprotectedFetch({
      baseURL: newApiBaseUrl,
      method: 'GET',
      url: newApiUrls.subscriptionOverview(data.practiceId),
    }),
  GET_PAYOUT_OVERVIEW: (data: GetPayoutOverviewArgs) =>
    unprotectedFetch({
      baseURL: newApiBaseUrl,
      method: 'GET',
      url: newApiUrls.payoutOverview(data.practiceId),
    }),
};

export const mutations = {
  // mutations for new backend API
  PATCH_AUTH_USER: {
    mutationFn: (data: PatchUserArgs) => fetchers.PATCH_AUTH_USER(data),
  },
  POST_PRACTITIONERS: {
    mutationFn: (data: PostPractitionersArgs) => fetchers.POST_PRACTITIONERS(data),
  },
  PATCH_PRACTITIONERS: {
    mutationFn: (data: PatchPractitionersArgs) => fetchers.PATCH_PRACTITIONERS(data),
  },
  POST_PRACTICE_MEMBERS: {
    mutationFn: (data: PostPracticeMembersArgs) => fetchers.POST_PRACTICE_MEMBERS(data),
  },
  PATCH_PRACTICE_MEMBERS: {
    mutationFn: (data: PatchPracticeMembersArgs) => fetchers.PATCH_PRACTICE_MEMBERS(data),
  },
  DELETE_PRACTICE_MEMBER: {
    mutationFn: (data: DeletePracticeMembersArgs) => fetchers.DELETE_PRACTICE_MEMBER(data),
  },
  PATCH_PRACTICE: {
    mutationFn: (data: PatchPracticeArgs) => fetchers.PATCH_PRACTICE(data),
  },
  POST_TREATMENT_GUIDES: {
    mutationFn: (data: PostTreatmentGuidesArgs) => fetchers.POST_TREATMENT_GUIDES(data),
  },
  PATCH_TREATMENT_GUIDES: {
    mutationFn: (data: PatchTreatmentGuideArgs, accessor: Accessor) => fetchers.PATCH_TREATMENT_GUIDE(data, accessor),
  },
  POST_AUTH_S3_PUT_OBJECT: {
    mutationFn: (data: PostAttachmentsArgs) => fetchers.POST_AUTH_S3_PUT_OBJECT(data),
  },
  GET_AUTH_S3_PUT_OBJECT: {
    mutationFn: (data: GetAttachmentsArgs, accessor: Accessor) => fetchers.GET_AUTH_S3_PUT_OBJECT(data, accessor),
  },
  GET_PATIENT_OVERVIEW: {
    mutationFn: (data: GetPatientOverviewArgs) => fetchers.GET_PATIENT_OVERVIEW(data),
  },
  GET_PATIENT_OVERVIEW_CSV: {
    mutationFn: (data: GetPatientOverviewArgs) => fetchers.GET_PATIENT_OVERVIEW_CSV(data),
  },
  POST_DIVIDEBUY_REDIRECT: {
    mutationFn: (data: PostDividebuyRedirectArgs) => fetchers.POST_DIVIDEBUY_REDIRECT(data),
  },
  POST_MAGIC_LINK: {
    mutationFn: (data: { email: string }, accessor: Accessor) => fetchers.POST_MAGIC_LINK(data, accessor),
  },
  POST_DIVIDEBUY_SOFT_SEARCH: {
    mutationFn: (data: { amount: number }) => fetchers.POST_DIVIDEBUY_SOFT_SEARCH(data),
  },
  POST_PRACTICE: {
    mutationFn: (data: PostPracticeArgs) => fetchers.POST_PRACTICE(data),
  },
  GET_ADDRESSES: {
    mutationFn: (data: { postcode: string }) => fetchers.GET_ADDRESSES(data),
  },
  POST_DIVIDEBUY_QUOTATION: {
    mutationFn: (data: PostQuotationBody) => fetchers.POST_DIVIDEBUY_QUOTATION(data),
  },
  POST_PLAN_SUBSCRIPTION: {
    mutationFn: (data: PostPlanSubscriptionArgs) => fetchers.POST_PLAN_SUBSCRIPTION(data),
  },
  POST_DIVIDEBUY_SELECT_QUOTE: {
    mutationFn: (data: PostSelectQuoteBody) => fetchers.POST_DIVIDEBUY_SELECT_QUOTE(data),
  },
  GET_SUBSCRIPTION_OVERVIEW: {
    mutationFn: (data: GetSubscriptionOverviewArgs) => fetchers.GET_SUBSCRIPTION_OVERVIEW(data),
  },
  GET_PAYOUT_OVERVIEW: {
    mutationFn: (data: GetPayoutOverviewArgs) => fetchers.GET_PAYOUT_OVERVIEW(data),
  },
};
